import { FC, createContext, useEffect, useState } from 'react';
import { GenericProps } from '../../types/types';
import { User, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import useFirebase from '../FirebaseProvider/useFirebase';

export interface IAuthContext {
    user: User | null;
}

export const AuthContext = createContext<IAuthContext | null>(null);

const AuthProvider: FC<GenericProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [, setAuthLoading] = useState(true);
    const [authFailed, setAuthFailed] = useState(false);
    const { auth } = useFirebase();

    // start auth listener on init
    useEffect(() => {
        let unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);

            setAuthLoading(false);
        });

        return unsubscribe;
    }, [auth]);

    // sign in user anonymously on init
    useEffect(() => {
        const anonymousSignIn = async () => {
            try {
                await signInAnonymously(auth);
            } catch (err) {
                console.error('Anonymous auth failed ', err);
                setAuthFailed(true);
            }
        }

        anonymousSignIn();
    }, [auth]);

    // consider showing an error because this would mean that
    // Firebase, auth, or recaptcha failed
    if (authFailed) {
        console.log('Authentication failed...');
    }

    // consider showing a loading page
    // if (authLoading) {
    //     return <></>;
    // }

    // values exposed via provider
    const theValues = {
        user
    }


    return (
        <AuthContext.Provider value={theValues}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;