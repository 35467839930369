export interface DisclaimerList {
    number: string;
    text: string;
    underlinedText: string;
    link: string;
}

export const disclaimerArray: DisclaimerList[] = [
    {
        number: '1',
        text: 'NIH: ',
        underlinedText: 'NBK218769',
        link: 'https://www.ncbi.nlm.nih.gov/books/NBK218769/'
    },
    {
        number: '2',
        text: 'Nature Education ',
        underlinedText: '3(9):11',
        link: 'https://www.nature.com/scitable/topicpage/nutrient-utilization-in-humans-metabolism-pathways-14234029/#:~:text=Humans%20obtain%20energy%20from%20three,kinetic%2C%20and%20other%20chemical%20forms.'
    },
    {
        number: '3',
        text: 'NIH: ',
        underlinedText: 'NBK470361',
        link: 'https://www.ncbi.nlm.nih.gov/books/NBK470361/'
    },
    {
        number: '4',
        text: 'NIH: ',
        underlinedText: 'ISSN 2375-9593',
        link: 'https://www.nih.gov/news-events/nih-research-matters/mapping-brain-circuits-involved-attention'
    },
    {
        number: '5',
        text: 'NIH: ',
        underlinedText: 'PMC3586760',
        link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3586760/'
    },
    {
        number: '6',
        text: 'NIH: ',
        underlinedText: 'PMC4864984',
        link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4864984/'
    }
]