import { ReactNode } from "react";
import PopupBioTitle from "./PopupDetails/PopupBioTitle";
import PopupBioDesc from "./PopupDetails/PopupBioDesc";
import adrenalGlands from "../../../../assets/images/BiologicalParts/AdrenalGlands.png";
import blood from "../../../../assets/images/BiologicalParts/Blood.png";
import bones from "../../../../assets/images/BiologicalParts/Bones.png";
import brain from "../../../../assets/images/BiologicalParts/Brain.png";
import cells from "../../../../assets/images/BiologicalParts/Cells.png";
import eye from "../../../../assets/images/BiologicalParts/Eye.png";
import heart from "../../../../assets/images/BiologicalParts/Heart.png";
import immuneSystem from "../../../../assets/images/BiologicalParts/ImmuneSystem.png";
import kidneys from "../../../../assets/images/BiologicalParts/Kidneys.png";
import liver from "../../../../assets/images/BiologicalParts/Liver.png";
import muscles from "../../../../assets/images/BiologicalParts/Muscles.png";
import nerveCells from "../../../../assets/images/BiologicalParts/NerveCells.png";
import smallIntestines from "../../../../assets/images/BiologicalParts/SmallIntestines.png";
import PopupHighlight from "./PopupDetails/PopupHighlight";

export type SupplementType =
  | "vitamin"
  | "mineral"
  | "aminoAcid"
  | "plantFamily";
export type CategoryType = "energized" | "calm" | "motivated" | "focused";

export const categoryTypeValues = ["energized", "calm", "motivated", "focused"];

export type BiologicalPart =
  | "Brain"
  | "Eye"
  | "Immune System"
  | "Skin"
  | "Cells"
  | "Liver"
  | "Adrenal Glands"
  | "Small Intestines"
  | "Nerve Cells"
  | "Bones"
  | "Muscles"
  | "Heart"
  | "Kidneys"
  | "Blood"
  | "Brain, Immune System, & Heart";

export interface Supplement {
  name: string;
  subtitle?: string;
  type: SupplementType;
  category: CategoryType[];
  whatIsIt: ReactNode;
  scienceLinks: {
    text: string;
    underlinedText: string;
    link: string;
  }[];
  biologicalDetails: ReactNode;
}

export const imageMap: { [key: string]: string } = {
  "Adrenal Glands": adrenalGlands,
  Blood: blood,
  Bones: bones,
  Brain: brain,
  Cells: cells,
  Eye: eye,
  Heart: heart,
  "Immune System": immuneSystem,
  Kidneys: kidneys,
  Liver: liver,
  Muscles: muscles,
  "Nerve Cells": nerveCells,
  "Small Intestines": smallIntestines,
};

export const supplementOptionsArray: {
  text: string;
  filter: SupplementType;
}[] = [
  { text: "Vitamins", filter: "vitamin" },
  { text: "Minerals", filter: "mineral" },
  { text: "Acids", filter: "aminoAcid" },
  { text: "Herbs", filter: "plantFamily" },
];

export const categoryOptionsArray: {
  text: string;
  filter: CategoryType;
  description: string;
}[] = [
  {
    text: "Energized",
    filter: "energized",
    description:
      "Energy, the capacity to do work, is obtained by breaking down nutrients such as carbohydrates, fats, and proteins in the digestive tract¹ ². The supplements below play a critical role in food energy metabolism, production, and absorption, allowing tissues and cells to transform fuel molecules into useful work.²",
  },
  {
    text: "Calm",
    filter: "calm",
    description:
      "Calm is the absence of anxiety, a future-oriented mood state that triggers an undue response to an event or situation that is mistakenly perceived as threatening.³ The supplements below can reduce anxiety and promote relaxation by contributing to the processes that regulate mood, stress reactions, and normal sleep.",
  },
  {
    text: "Motivated",
    filter: "motivated",
    description:
      "Motivation, the energizing of behavior in pursuit of a goal, is influenced by stress, health, and dopamine, a neurotransmitter associated with reward anticipation and goal-oriented behavior. The supplements below can promote motivation by helping regulate stress reactions, immune responses, and dopamine levels.⁵ ⁶",
  },
  {
    text: "Focused",
    filter: "focused",
    description:
      "Focus, the ability to pay attention, involves the use of neurons to filter out sensory information the brain should ignore⁴. The supplements below play a critical role in the development and survival of neurons, the delivery of information by neurons, and the regulation of attention, allowing the brain to receive information and focus properly.",
  },
];

export const supplementsArray: Supplement[] = [
  {
    name: "Vitamin A",
    subtitle: "Retinol",
    type: "vitamin",
    category: ["focused"],
    whatIsIt: (
      <>
        <PopupBioDesc>
          Vitamin A, also known as retinol, is an essential{" "}
          <PopupHighlight>fat-soluble</PopupHighlight> vitamin found primarily
          in animal products, but also in vegetables and fruits rich in{" "}
          <PopupHighlight>carotenoids</PopupHighlight>.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "MDPI: ",
        underlinedText: "NU13051703",
        link: "https://www.mdpi.com/2072-6643/13/5/1703",
      },
      {
        text: "PMID: ",
        underlinedText: "20672742",
        link: "https://pubmed.ncbi.nlm.nih.gov/20672742/#:~:text=Regulatory%20T%20cells%20(Tregs)%20are,critical%20role%20in%20preventing%20autoimmunity.",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC8157347",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8157347/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK482362",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK482362/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3614697",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3614697/",
      },
      {
        text: "NIH: ",
        underlinedText: "Gene Regulation",
        link: "https://www.genome.gov/genetics-glossary/Gene-Regulation#:~:text=Gene%20regulation%20is%20the%20process,and%20chemical%20modification%20of%20DNA.",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Vitamin A takes part in the transmission of optic perceptions via the{" "}
          <PopupHighlight>optic nerve</PopupHighlight> to the brain.
        </PopupBioDesc>

        <PopupBioTitle>Eye</PopupBioTitle>
        <PopupBioDesc>
          Vitamin A contributes to the development of the{" "}
          <PopupHighlight>cornea</PopupHighlight> and{" "}
          <PopupHighlight>conjunctiva</PopupHighlight> in the eye.
        </PopupBioDesc>

        <PopupBioTitle>Immune System</PopupBioTitle>
        <PopupBioDesc>
          Vitamin A indirectly stimulates the production of{" "}
          <PopupHighlight>regulatory T cells</PopupHighlight> (Tregs) that act
          to suppress immune response, thereby maintaining{" "}
          <PopupHighlight>homeostasis</PopupHighlight> and preventing{" "}
          <PopupHighlight>autoimmunity</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Skin</PopupBioTitle>
        <PopupBioDesc>
          Vitamin A maintains the functional and structural integrity of the{" "}
          <PopupHighlight>epithelium</PopupHighlight> in different tissues.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin A also indirectly promotes{" "}
          <PopupHighlight>epidermal</PopupHighlight> turnover and normal tissue
          restoration in wounded tissues.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Vitamin A decreases damage from{" "}
          <PopupHighlight>free radicals</PopupHighlight>, toxic and beneficial
          compounds that can be either helpful or harmful to the body, through
          its <PopupHighlight>antioxidant</PopupHighlight> effects.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin A also plays a role in gene regulation, the process used to
          control the timing, location, and amount of{" "}
          <PopupHighlight>gene expression</PopupHighlight>, by interacting with
          the <PopupHighlight>nuclear receptors</PopupHighlight> of cells.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Vitamin B3",
    subtitle: "Niacin",
    type: "vitamin",
    category: ["energized", "focused"],
    whatIsIt: (
      <>
        <PopupBioDesc>
          Vitamin B3, also known as niacin, is an essential{" "}
          <PopupHighlight>water-soluble</PopupHighlight> vitamin found in
          enriched and whole grains and high protein foods like meat, milk, and
          eggs.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "PMID: ",
        underlinedText: "30813414",
        link: "https://pubmed.ncbi.nlm.nih.gov/30813414/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK541036",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK541036/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK557728",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK557728/",
      },
      {
        text: "NIH: ",
        underlinedText: "Niacin-HealthProfessional",
        link: "https://ods.od.nih.gov/factsheets/Niacin-HealthProfessional/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3614697",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3614697/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Nerve Cells</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B3 plays a role in the development and survival of{" "}
          <PopupHighlight>neurons</PopupHighlight>, so it is important to the
          proper functioning of the{" "}
          <PopupHighlight>central nervous system</PopupHighlight> (CNS).
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B3 is needed for energy production involving the{" "}
          <PopupHighlight>metabolism</PopupHighlight> of{" "}
          <PopupHighlight>macronutrients</PopupHighlight> such as carbohydrates,
          proteins, and fats.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin B3 also plays a critical role in maintaining cellular{" "}
          <PopupHighlight>antioxidant</PopupHighlight> function. Antioxidants
          protect against <PopupHighlight>free radicals</PopupHighlight>, toxic
          and beneficial compounds that can be either helpful or harmful to the
          body.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin B3 is required for <PopupHighlight>enzymes</PopupHighlight>{" "}
          involved in critical cellular functions, including the maintenance of{" "}
          <PopupHighlight>genome</PopupHighlight> integrity, control of{" "}
          <PopupHighlight>gene expression</PopupHighlight>,{" "}
          <PopupHighlight>cell cycle</PopupHighlight> progression,{" "}
          <PopupHighlight>DNA</PopupHighlight> repair, cell communication, and
          cell death.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Vitamin B5",
    subtitle: "Pantothenic acid",
    type: "vitamin",
    category: ["energized", "focused"],
    whatIsIt: (
      <>
        <PopupBioDesc>
          Vitamin B5, also known as pantothenic acid, is an essential{" "}
          <PopupHighlight>water-soluble</PopupHighlight> vitamin that is
          widespread in foods.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7019700",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7019700/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK557825",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK557825/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC8839250",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8839250/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK548626",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK548626/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC6222795",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6222795/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK538510",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK538510/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK563233",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK563233/",
      },
      {
        text: "NIH: ",
        underlinedText: "Gene-Expression",
        link: "https://www.genome.gov/genetics-glossary/Gene-Expression#:~:text=Gene%20expression%20is%20the%20process,molecules%20that%20serve%20other%20functions.",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B5 is needed for the release of{" "}
          <PopupHighlight>neurotransmitters</PopupHighlight> in the{" "}
          <PopupHighlight>synapse</PopupHighlight> of{" "}
          <PopupHighlight>nerve cells</PopupHighlight>, which is important for
          sending information to the brain.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin B5 is also required for the growth of{" "}
          <PopupHighlight>acetylcholine</PopupHighlight>, a neurotransmitter
          that acts as a chemical message. It is released by{" "}
          <PopupHighlight>neurons</PopupHighlight> and allows them to
          communicate with one another.
        </PopupBioDesc>

        <PopupBioTitle>Liver</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B5 is necessary for the growth of bile acids in the liver. In
          the intestines, <PopupHighlight>bile acids</PopupHighlight> help to
          emulsify fats, aiding in their digestion and absorption.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin B5 is also necessary for the growth of fatty acids in the
          liver. In cells, <PopupHighlight>fatty acids</PopupHighlight> function
          as structural “building blocks” of{" "}
          <PopupHighlight>cell membranes</PopupHighlight>, suppliers of energy,
          and <PopupHighlight>signaling molecules</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B5 plays a role in the{" "}
          <PopupHighlight>metabolism of fats</PopupHighlight>, energy
          production, cell growth, and the formation of hormones.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin B5 also plays a role in gene expression, the process by which
          the information encoded in a <PopupHighlight>gene</PopupHighlight> is
          turned into a function.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Vitamin B6",
    subtitle: "Pyridoxine",
    type: "vitamin",
    category: ["energized", "calm", "motivated", "focused"],
    whatIsIt: (
      <>
        <PopupBioDesc>
          Vitamin B6, also known as pyridoxine, is an essential{" "}
          <PopupHighlight>water-soluble</PopupHighlight> vitamin that is
          widespread in foods, including meat, fish, nuts, beans, grains,
          fruits, and vegetables.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK470579",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK470579/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK557436",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK557436/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7019700",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7019700/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC6472684",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6472684/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK538510",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK538510/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK2263",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK2263/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK539702",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK539702/#:~:text=Introduction,carry%20out%20their%20essential%20functions.",
      },
      {
        text: "NIH: ",
        underlinedText: "VitaminB6-HealthProfessional",
        link: "http://vitaminb6-healthprofessional/",
      },
      {
        text: "NIH: ",
        underlinedText: "Nutrition Journal",
        link: "https://nutritionj.biomedcentral.com/articles/10.1186/1475-2891-14-6",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B6 is needed for the growth of major neurotransmitters like
          serotonin and dopamine, which are important in mood regulation.
        </PopupBioDesc>

        <PopupBioTitle>Immune System</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B5 is necessary for the growth of bile acids in the liver. In
          the intestines, <PopupHighlight>bile acids</PopupHighlight> help to
          emulsify fats, aiding in their digestion and absorption.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin B5 is also necessary for the growth of{" "}
          <PopupHighlight>fatty acids</PopupHighlight> in the liver. In cells,
          fatty acids function as structural “building blocks” of{" "}
          <PopupHighlight>cell membranes</PopupHighlight>, suppliers of energy,
          and <PopupHighlight>signaling molecules</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B5 plays a role in the{" "}
          <PopupHighlight>metabolism</PopupHighlight> of fats, energy
          production, cell growth, and the formation of{" "}
          <PopupHighlight>hormones</PopupHighlight>.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin B5 also plays a role in{" "}
          <PopupHighlight>gene expression</PopupHighlight>, the process by which
          the information encoded in a <PopupHighlight>gene</PopupHighlight> is
          turned into a function.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Vitamin B12",
    subtitle: "Cobalamin",
    type: "vitamin",
    category: ["energized", "focused"],
    whatIsIt: (
      <>
        <PopupBioDesc>
          Vitamin B12, also known as cobalamin, is an essential{" "}
          <PopupHighlight>water-soluble</PopupHighlight> vitamin that is derived
          from animal products such as red meat, dairy, and eggs or sourced from
          fortified foods in synthetic form.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "NIH: ",
        underlinedText: "VitaminB12-HealthProfessional",
        link: "https://ods.od.nih.gov/factsheets/VitaminB12-HealthProfessional/",
      },
      {
        text: "NIH: ",
        underlinedText: "Nervous System",
        link: "https://www.nichd.nih.gov/health/topics/neuro/conditioninfo/parts",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC5017146",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5017146/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK559132",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK559132/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC6222795",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6222795/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK2263",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK2263/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK539702",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK539702/#:~:text=Introduction,carry%20out%20their%20essential%20functions.",
      },
      {
        text: "NIH: ",
        underlinedText: "Deoxyribonucleic-Acid-Fact-Sheet",
        link: "https://www.genome.gov/about-genomics/fact-sheets/Deoxyribonucleic-Acid-Fact-Sheet",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Required for the development and function of the{" "}
          <PopupHighlight>central nervous system</PopupHighlight> (CNS), which
          is made up of the brain and the spinal cord.
        </PopupBioDesc>
        <PopupBioDesc>
          Required for the growth of myelin in the{" "}
          <PopupHighlight>central nervous system</PopupHighlight> (CNS) and{" "}
          <PopupHighlight>peripheral nervous system</PopupHighlight> (PNS).{" "}
          <PopupHighlight>Myelin</PopupHighlight> is a multilayer wrapping of
          insulation formed by <PopupHighlight>glial cells</PopupHighlight>{" "}
          around the axons of neurons that is essential for rapid impulse
          transmission.
        </PopupBioDesc>

        <PopupBioTitle>Liver</PopupBioTitle>
        <PopupBioDesc>
          Required for the growth of{" "}
          <PopupHighlight>fatty acids</PopupHighlight> in the liver. In cells,
          fatty acids function as structural “building blocks” of{" "}
          <PopupHighlight>cell membranes</PopupHighlight>, suppliers of energy,
          and signaling molecules.
        </PopupBioDesc>

        <PopupBioTitle>Bones</PopupBioTitle>
        <PopupBioDesc>
          Required for the formation of healthy{" "}
          <PopupHighlight>red blood cells</PopupHighlight> in the bone marrow.{" "}
          <PopupHighlight>Red blood cells</PopupHighlight> are the functional
          component of the blood responsible for the transportation of gases and
          nutrients throughout the human body.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Required for the growth of DNA in cells.{" "}
          <PopupHighlight>DNA</PopupHighlight> is a molecule that contains the
          biological instructions that make each species unique.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Vitamin C",
    subtitle: "Ascorbic acid",
    type: "vitamin",
    category: ["energized", "calm", "motivated"],
    whatIsIt: (
      <>
        <PopupBioDesc>
          Vitamin C, also known as ascorbic acid, is an essential{" "}
          <PopupHighlight>water-soluble</PopupHighlight> vitamin found primarily
          in fruits and vegetables.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK499877",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK499877/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK507709",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK507709/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7918462",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7918462/",
      },
      {
        text: "NIH: ",
        underlinedText: "Carnitine",
        link: "https://ods.od.nih.gov/factsheets/Carnitine-HealthProfessional/#:~:text=Carnitine%20plays%20a%20critical%20role,(ATP)%20%5B4%5D.",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK482160",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK482160/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK448065",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK448065/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK259",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK259/#:~:text=Definition,deciliter%20(g%2Fdl).",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK493187",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK493187/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3614697",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3614697/",
      },
      {
        text: "NIH: ",
        underlinedText: "Gene Regulation",
        link: "https://www.genome.gov/genetics-glossary/Gene-Regulation#:~:text=Gene%20regulation%20is%20the%20process,and%20chemical%20modification%20of%20DNA.",
      },
      {
        text: "PMID: ",
        underlinedText: "26290474",
        link: "https://pubmed.ncbi.nlm.nih.gov/26290474/",
      },
      {
        text: "PMID: ",
        underlinedText: "22228951",
        link: "https://pubmed.ncbi.nlm.nih.gov/22228951/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Skin</PopupBioTitle>
        <PopupBioDesc>
          Vitamin C is needed for wound healing and the growth of{" "}
          <PopupHighlight>collagen</PopupHighlight>, a protein molecule that is
          the perfect matrix for skin, tendons, bones, and ligaments due to its
          rigidity and resistance to stretching.
        </PopupBioDesc>

        <PopupBioTitle>Liver</PopupBioTitle>
        <PopupBioDesc>
          Vitamin C is needed for the growth of{" "}
          <PopupHighlight>carnitine</PopupHighlight>, a{" "}
          <PopupHighlight>cofactor</PopupHighlight> that plays a critical role
          in energy production.
        </PopupBioDesc>

        <PopupBioTitle>Adrenal Glands</PopupBioTitle>
        <PopupBioDesc>
          Vitamin C is needed for the growth of{" "}
          <PopupHighlight>adrenaline/epinephrine</PopupHighlight>, a hormone
          that plays a vital role in the body’s acute stress response by
          stimulating the{" "}
          <PopupHighlight>sympathetic nervous system</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Small Intestines</PopupBioTitle>
        <PopupBioDesc>
          Vitamin C plays a role in iron absorption by reducing iron in the{" "}
          <PopupHighlight>gastrointestinal tract</PopupHighlight>. Iron is
          essential for the production of{" "}
          <PopupHighlight>hemoglobin</PopupHighlight>, a protein contained in
          red blood cells that is responsible for the delivery of oxygen to
          tissues.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Vitamin C protects cells from{" "}
          <PopupHighlight>free radicals</PopupHighlight>, toxic and beneficial
          compounds that can be either helpful or harmful to the body, due to
          its significant antioxidant properties.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin C also plays a role in gene regulation, the process used to
          control the timing, location, and amount in which genes are expressed.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Vitamin D",
    subtitle: "Calciferol",
    type: "vitamin",
    category: ["motivated", "focused"],
    whatIsIt: (
      <>
        <PopupBioDesc>
          Vitamin D or cholecalciferol, also known as the “sunshine vitamin,” is
          a <PopupHighlight>fat-soluble</PopupHighlight> hormone produced in the
          skin during sun exposure or obtained through dietary consumption.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK441912",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK441912/",
      },
      {
        text: "NIH: ",
        underlinedText: "VitaminD-Consumer",
        link: "https://ods.od.nih.gov/factsheets/VitaminD-Consumer/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7839822",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7839822/",
      },
      {
        text: "NIH: ",
        underlinedText: "VitaminD-HealthProfessional",
        link: "https://ods.od.nih.gov/factsheets/VitaminD-HealthProfessional/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3166406",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3166406/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC5612831",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5612831/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK545201",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK545201/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Needed to carry messages between the brain and the body. Regulates
          essential processes in normal brain development and is{" "}
          <PopupHighlight>neuroprotective</PopupHighlight> in the adult brain.
        </PopupBioDesc>

        <PopupBioTitle>Immune System</PopupBioTitle>
        <PopupBioDesc>
          Plays a role in the reduction of{" "}
          <PopupHighlight>inflammation</PopupHighlight> and the modulation of{" "}
          <PopupHighlight>innate</PopupHighlight> and{" "}
          <PopupHighlight>adaptive immune responses</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Muscles</PopupBioTitle>
        <PopupBioDesc>
          Needed to enable the movement of the muscles. Helps to prevent{" "}
          <PopupHighlight>hypocalcemic tetany</PopupHighlight>, which is the
          involuntary contraction of muscles that can lead to cramps and spasms.
        </PopupBioDesc>

        <PopupBioTitle>Bones</PopupBioTitle>
        <PopupBioDesc>
          Needed for bone growth and bone remodeling by{" "}
          <PopupHighlight>osteoblasts</PopupHighlight> and{" "}
          <PopupHighlight>osteoclasts</PopupHighlight>. Osteoclasts are cells
          responsible for aged bone resorption and osteoblasts are cells
          responsible for new bone formation.
        </PopupBioDesc>

        <PopupBioTitle>Small Intestines</PopupBioTitle>
        <PopupBioDesc>
          Promotes calcium absorption in the gut and maintains adequate calcium
          and phosphate levels to help build strong bones.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Plays a role in cell growth and the{" "}
          <PopupHighlight>metabolism</PopupHighlight> of{" "}
          <PopupHighlight>glucose</PopupHighlight>, a source of energy for every
          organism in the world.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Potassium",
    subtitle: "Potassium Citrate",
    type: "mineral",
    category: ["focused"],
    whatIsIt: (
      <>
        <PopupBioDesc>
          Potassium is an essential macro mineral and{" "}
          <PopupHighlight>electrolyte</PopupHighlight> found in foods like
          fruits and vegetables.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "Springer: ",
        underlinedText: "The Role of Potassium",
        link: "https://link.springer.com/chapter/10.1007/978-1-4615-1061-1_18",
      },
      {
        text: "NIH: ",
        underlinedText: "35195759",
        link: "https://pubmed.ncbi.nlm.nih.gov/35195759/",
      },
      {
        text: "NIH: ",
        underlinedText: "Potassium-HealthProfessional",
        link: "https://ods.od.nih.gov/factsheets/Potassium-HealthProfessional/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Heart</PopupBioTitle>
        <PopupBioDesc>
          Plays a critical role in the transmission of electrical impulses in
          the heart. Creates the electrical potential that helps generate muscle
          contractions and regulates the heartbeat.
        </PopupBioDesc>

        <PopupBioTitle>Kidneys</PopupBioTitle>
        <PopupBioDesc>
          Needed to carry messages between the brain and the body. Regulates
          essential processes in normal brain development and is{" "}
          <PopupHighlight>neuroprotective</PopupHighlight> in the adult brain.
        </PopupBioDesc>
        <PopupBioDesc>
          The kidneys play a key role in maintaining whole-body potassium
          balance but potassium also affects kidney function. A low potassium
          level in the urine is increasingly recognized as a risk factor for the
          progression of <PopupHighlight>kidney disease</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Required for normal cell function because of its role in maintaining
          fluid volume within cells and{" "}
          <PopupHighlight>electrochemical</PopupHighlight> gradients across{" "}
          <PopupHighlight>cell membranes</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Nerve Cells</PopupBioTitle>
        <PopupBioDesc>
          When potassium enters the <PopupHighlight>nerve</PopupHighlight> cell,
          it creates the <PopupHighlight>electrical potential</PopupHighlight>{" "}
          that allows the conduction of{" "}
          <PopupHighlight>nerve impulses</PopupHighlight>. When potassium leaves
          the nerve cell, it restores{" "}
          <PopupHighlight>repolarization</PopupHighlight> to the cell, which
          allows the nerve impulse to progress.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Magnesium",
    subtitle: "Magnesium Glycinate",
    type: "mineral",
    category: ["energized", "calm", "motivated", "focused"],
    whatIsIt: (
      <>
        <PopupBioDesc>
          Magnesium is an essential macro mineral found in foods such as fruits,
          vegetables, whole grains, legumes, nuts, dairy, meat, and fortified
          foods like cereal.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7019700",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7019700/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC5452159",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5452159/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK538239",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK538239/",
      },
      {
        text: "NIH: ",
        underlinedText: "Magnesium-HealthProfessional",
        link: "https://ods.od.nih.gov/factsheets/Magnesium-HealthProfessional/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK553175",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK553175/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3775240",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3775240/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC5612831",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5612831/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC4549665",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4549665/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC4684116",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4684116/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Needed for <PopupHighlight>neuromuscular</PopupHighlight> coordination
          and optimal <PopupHighlight>nerve transmission</PopupHighlight>.
          Protects against the overexcitation of nerves, which can lead to{" "}
          <PopupHighlight>oxidative stress</PopupHighlight> and neuronal cell
          death.
        </PopupBioDesc>

        <PopupBioTitle>Adrenal Glands</PopupBioTitle>
        <PopupBioDesc>
          Has been shown to reduce the production of cortisol, the body’s stress
          hormone, by the adrenal glands. Magnesium may influence anxiety via
          the moderation of the stress response.
        </PopupBioDesc>

        <PopupBioTitle>Bones</PopupBioTitle>
        <PopupBioDesc>
          Contributes to the structural development of bone. Magnesium is
          essential to <PopupHighlight>osteoblasts</PopupHighlight>, cells
          responsible for aged bone resorption and{" "}
          <PopupHighlight>osteoclasts</PopupHighlight>, cells responsible for
          new bone formation.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Plays a predominant role in the production and utilization of{" "}
          <PopupHighlight>adenosine triphosphate</PopupHighlight> (ATP). ATP is
          commonly referred to as the “energy currency” of the cell, as it
          provides readily releasable energy for daily functioning.
        </PopupBioDesc>
        <PopupBioDesc>
          Plays a role in the <PopupHighlight>active transport</PopupHighlight>{" "}
          of <PopupHighlight>calcium</PopupHighlight> and{" "}
          <PopupHighlight>potassium ions</PopupHighlight> across cell{" "}
          <PopupHighlight>membranes</PopupHighlight>. The process is important
          to <PopupHighlight>nerve impulse</PopupHighlight> conduction, muscle
          contraction, and normal heart rhythm.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Sodium",
    subtitle: "Sodium Citrate",
    type: "mineral",
    category: ["focused"],
    whatIsIt: (
      <PopupBioDesc>
        Sodium is an essential macro mineral and{" "}
        <PopupHighlight>electrolyte</PopupHighlight> found in dietary sources
        such as salt, processed foods, meat, milk, eggs, and vegetables.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3951800",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3951800/",
      },
      {
        text: "Springer: ",
        underlinedText: "The Role of Potassium",
        link: "https://link.springer.com/chapter/10.1007/978-1-4615-1061-1_18",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7318881",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7318881/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7312725",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7312725/",
      },
      {
        text: "NIH: ",
        underlinedText: "1375602",
        link: "https://pubmed.ncbi.nlm.nih.gov/1375602/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Blood</PopupBioTitle>
        <PopupBioDesc>
          Involved in the regulation of blood pressure. Regulates the water
          balance and the <PopupHighlight>acid-base</PopupHighlight> balance in
          the blood and tissues.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Involved in the maintenance of normal cellular{" "}
          <PopupHighlight>homeostasis</PopupHighlight>. Regulates the plasma
          volume in cells and the transport of nutrients across cell membranes.
        </PopupBioDesc>
        <PopupBioDesc>
          Important for the excitability of muscle and{" "}
          <PopupHighlight>nerve</PopupHighlight> cells.{" "}
          <PopupHighlight>Excitability</PopupHighlight> is fundamental for the
          production of <PopupHighlight>motor</PopupHighlight> behavior and
          cognition.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "L-Theanine",
    subtitle: "Sodium Citrate",
    type: "aminoAcid",
    category: ["calm", "motivated", "focused"],
    whatIsIt: (
      <PopupBioDesc>
        L-theanine is a unique <PopupHighlight>amino acid</PopupHighlight> and a
        natural component of tea. An amino acid is an organic compound that is
        required for the growth of body protein and other important{" "}
        <PopupHighlight>nitrogen</PopupHighlight>-containing compounds.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "18296328",
        link: "https://pubmed.ncbi.nlm.nih.gov/18296328/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK557845",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK557845/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK234922",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK234922/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3507158",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3507158/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC8080935",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8080935/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Significantly increases the activity of{" "}
          <PopupHighlight>alpha waves</PopupHighlight>, which play a role in
          brain activity at different{" "}
          <PopupHighlight>frequencies</PopupHighlight>. An increase in alpha
          wave activity indicates that L-theanine relaxes the mind without
          inducing drowsiness.
        </PopupBioDesc>
        <PopupBioDesc>
          Has been shown to affect brain functions by relieving stress
          disorders, improving mood, and maintaining normal sleep.
        </PopupBioDesc>

        <PopupBioTitle>Nerve Cells</PopupBioTitle>
        <PopupBioDesc>
          Reported to have a neuroprotective effect. L-theanine can potentially
          reduce cognitive impairment because it promotes the growth of new
          neurons.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "L-Tyrosine",
    subtitle: "Sodium Citrate",
    type: "aminoAcid",
    category: ["calm", "motivated", "focused"],
    whatIsIt: (
      <PopupBioDesc>
        L-tyrosine is a large non-essential{" "}
        <PopupHighlight>neutral amino acid</PopupHighlight> (LNAA) that is
        naturally present in food. An amino acid is an organic compound that is
        required for the growth of body protein and other important{" "}
        <PopupHighlight>nitrogen</PopupHighlight>-containing compounds.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "PMC6084775",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6084775/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK557845",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK557845/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK234922",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK234922/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7019700",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7019700/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK540977",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK540977/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC1863555",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1863555/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          L-tyrosine is a precursor to{" "}
          <PopupHighlight>neurotransmitters</PopupHighlight> like{" "}
          <PopupHighlight>dopamine</PopupHighlight> and{" "}
          <PopupHighlight>noradrenaline</PopupHighlight>. Dopamine is important
          in mood regulation. Noradrenaline plays an essential role in the
          regulation of arousal, attention, cognitive function, and stress
          reactions.
        </PopupBioDesc>
        <PopupBioDesc>
          In young adults, L-tyrosine improved cognitive control functions such
          as <PopupHighlight>response inhibition</PopupHighlight>, task
          switching, and working memory, especially in demanding circumstances.
        </PopupBioDesc>
        <PopupBioDesc>
          Reported to prevent a decline in cognitive function in response to
          physical stress.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "BCAAs",
    subtitle: "Branched Amino Acids",
    type: "aminoAcid",
    category: [],
    whatIsIt: (
      <PopupBioDesc>
        Branched chain <PopupHighlight>amino acids</PopupHighlight> (BCAAs),
        including <PopupHighlight>leucine</PopupHighlight>,{" "}
        <PopupHighlight>isoleucine</PopupHighlight>, and{" "}
        <PopupHighlight>valine</PopupHighlight>, are nutritionally essential
        building blocks of proteins,{" "}
        <PopupHighlight>neurotransmitters</PopupHighlight>, and{" "}
        <PopupHighlight>hormones</PopupHighlight>.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK209312",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK209312/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK55784",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK557845/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC8586693",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8586693/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK513311",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK513311/",
      },
      {
        text: "Frontiers: ",
        underlinedText: "Impact of Branched Chain Amino Acid",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK513311/",
      },
      {
        text: "Nutrition & Metabolism: ",
        underlinedText: "Branched Chain Amino Acids",
        link: "https://nutritionandmetabolism.biomedcentral.com/articles/10.1186/s12986-018-0271-1",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          BCAAs contribute to the growth of{" "}
          <PopupHighlight>glutamate</PopupHighlight>, a{" "}
          <PopupHighlight>neurotransmitter</PopupHighlight> that is important to
          memory, cognition, and mood regulation.
        </PopupBioDesc>
        <PopupBioDesc>
          BCAAs also play a part in the growth of{" "}
          <PopupHighlight>gamma-aminobutyric acid</PopupHighlight> (GABA), a
          major <PopupHighlight>neurotransmitter</PopupHighlight> that regulates
          complex circuits in the{" "}
          <PopupHighlight>central nervous system (CNS)</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Muscles</PopupBioTitle>
        <PopupBioDesc>
          A 2021 study found that BCAAs improved muscular strength, muscle
          function, and muscle mass. Studies show that{" "}
          <PopupHighlight>leucine</PopupHighlight>, one of the BCAAs, decreases
          appetite and may decrease body fat.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Day Lily",
    subtitle: "Hemerocallis Fulva",
    type: "plantFamily",
    category: ["calm", "motivated", "focused"],
    whatIsIt: (
      <PopupBioDesc>
        Daylily, also known as Hemerocallis fulva, is a flower that is
        traditionally used for soothing in Chinese dietary therapy.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK209312",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK209312/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain, Immune System, & Heart</PopupBioTitle>
        <PopupBioDesc>
          Daylilies are rich in <PopupHighlight>flavonoids</PopupHighlight>,{" "}
          <PopupHighlight>antioxidants</PopupHighlight> that have{" "}
          <PopupHighlight>neuroprotective</PopupHighlight> effects in the brain,{" "}
          <PopupHighlight>anti-inflammatory</PopupHighlight> properties in the
          immune system, and <PopupHighlight>cardio-protective</PopupHighlight>{" "}
          effects in the heart. Studies indicate that daylily flowers greatly
          improve sleeping and memory.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Daylily flowers are rich in{" "}
          <PopupHighlight>antioxidants</PopupHighlight>, molecules that delay or
          inhibit cell damage by scavenging{" "}
          <PopupHighlight>free radicals</PopupHighlight>. Free radicals are
          toxic and beneficial compounds that can be either helpful or harmful
          to the body.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Sulforaphane",
    subtitle: "Green Tea Extract",
    type: "plantFamily",
    category: ["focused"],
    whatIsIt: (
      <PopupBioDesc>
        Sulforaphane (SFN) is an <PopupHighlight>isothiocyanate</PopupHighlight>{" "}
        (ITC), a biologically active small molecule found in some vegetables.
        Broccoli is a major source of SFN.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK209312",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK209312/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Sulforaphane may have a promising role in{" "}
          <PopupHighlight>neuroprotection</PopupHighlight>. SFN exhibits strong{" "}
          <PopupHighlight>anti-inflammatory</PopupHighlight> and{" "}
          <PopupHighlight>antioxidant</PopupHighlight> properties which allow it
          to dramatically reduce <PopupHighlight>cytotoxicity</PopupHighlight>{" "}
          in the nervous system.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Sulforaphane plays a role in promoting and regulating cellular
          defenses to <PopupHighlight>oxidative stress</PopupHighlight> and
          alleviating the damage to cells.
        </PopupBioDesc>
        <PopupBioDesc>
          Sulforaphane also promotes{" "}
          <PopupHighlight>programmed cell death</PopupHighlight> when cells are
          no longer needed. It induces{" "}
          <PopupHighlight>cell cycle arrest</PopupHighlight>, the inability of
          the cell to continue dividing, which can be an alarm response to
          prevent the propagation of dysfunctional cells.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Green Tea",
    subtitle: "Green Tea Extract",
    type: "plantFamily",
    category: ["motivated", "focused"],
    whatIsIt: (
      <PopupBioDesc>
        Green tea, consisting of leaves from the plant, Camellia sinensis, is
        used as a beverage and dietary supplement.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK209312",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK209312/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Green tea contains several substances that affect cognitive function,
          including <PopupHighlight>caffeine</PopupHighlight>,{" "}
          <PopupHighlight>L-theanine</PopupHighlight>, and{" "}
          <PopupHighlight>catechin</PopupHighlight>. There is evidence that
          these components can reduce anxiety, benefit memory and attention, and
          activate the <PopupHighlight>working memory</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Immune System</PopupBioTitle>
        <PopupBioDesc>
          Green tea strengthens immune system action because it protects it
          against <PopupHighlight>free radicals</PopupHighlight>, toxic and
          beneficial compounds that can be either helpful or harmful to the
          body.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Green tea was found to be effective in preventing cellular damage from{" "}
          <PopupHighlight>oxidative stress</PopupHighlight>, a disturbance in
          the balance between the production of{" "}
          <PopupHighlight>free radicals</PopupHighlight> and{" "}
          <PopupHighlight>antioxidant</PopupHighlight> defenses.
        </PopupBioDesc>
      </>
    ),
  },
];
