import { FC } from 'react';
import { GenericProps } from '../../../../../types/types';

/**
 * Text shown below title on hero. Hidden on xs screens.
 * Includes font styles including type, size, weight, and spacing for
 *  various screen sizes
 */
const HeroText: FC<GenericProps> = ({ children, className = '' }) => {
    return (
        <p className={`YasuLibHeroText hidden sm:block font-quattro text-base sm:text-2xl font-bold leading-7 sm:leading-10 ${className}`}>{children}</p>
    );
}

export default HeroText;