/** Difference between URL and Name: name is what user see in UI URL is the href used in that component */
export type link = {
  name: string;
  url: string;
};

/** Title is made optional due to usa case in the card (card doesn't need title). Title is used for filterin in the Creators component */
export type Creator = {
  title?: title;
  fullName: string;
  copy: string;
  links: link[];
};

/** Title options. Numbers are optional added for possible future use cases */
export enum title {
  Artists = 1,
  TechnologyAndMultimedia = 2,
  DigitalAndStrategy = 3,
}

/** Creators array every item added to this list will be displayed dinamically in the coresponding section (wont be displayed if section is not defined in the Creators page) */
export const creatorsArray: Creator[] = [
  {
    title: title.Artists,
    fullName: "Gianna Croft",
    copy: "Gianna Croft is an artist based out of Bosnia who is the brilliant painter of many elements including The World of Yasu, and The Sinkhole and characters; Evelina, The Queen of the Sky City,  and Izzy.",
    links: [
      { name: "Art Station", url: "https://www.artstation.com/sylleth" },
      { name: "Cara", url: "https://cara.app/sylleth" },
    ],
  },
  {
    title: title.Artists,
    fullName: "Alex Myles",
    copy: "Alex is an audio producer in Toronto and he is the master of the main theme song of Yasu. He is currently  designing various sounds and music for each of the special Yasu environments. ",
    links: [
      {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/alex-myles-6a51b7199/",
      },
    ],
  },
  {
    title: title.Artists,
    fullName: "Ryan Persaud",
    copy: "Ryan is a designer and artist based out of Toronto who created the magical palette of Yasu. He is also the designer of the magical and brilliant  logo for The World of Yasu.",
    links: [
      { name: "Portfolio", url: "https://torodesign.ca/" },
      { name: "LinkedIn ", url: "https://www.linkedin.com/in/torodesigns/" },
    ],
  },
  {
    title: title.Artists,
    fullName: "Johannes Roots",
    copy: "Johannes is a painter based out of Estonia who painted various environments. “I want to bring a sense of wonder back to peoples’ lives through the gorgeous and exciting worlds I portray in my paintings.”",
    links: [
      { name: "Portfolio", url: "https://jkroots.com/" },
      { name: "Deviant", url: "https://www.deviantart.com/jkroots" },
      { name: "Instagram", url: "https://www.instagram.com/johannesroots/" },
      { name: "Facebook", url: "https://www.facebook.com/jkroots/" },
    ],
  },
  {
    title: title.Artists,
    fullName: "Zach Hu",
    copy: "Zach is a graphic designer based out of Toronto who created the original logo for Yasu when it was branded under Spark and did the initial variations of animated content that inspired the brand. ",
    links: [
      { name: "LinkedIn", url: "https://www.linkedin.com/in/zacharyhu/" },
    ],
  },
  {
    title: title.Artists,
    fullName: "Casper Symons",
    copy: "Casper is an Illustrator based out of the state of New York who created the beverage packaging and logo for Kana’s Drink and lead the brilliant illustrations on the human body and anatomy.  ",
    links: [
      { name: "Portfolio", url: "https://csymons.art/" },
      { name: "LinkedIn", url: "https://www.linkedin.com/in/casper-symons/" },
    ],
  },
  {
    title: title.Artists,
    fullName: "Kristen Chong",
    copy: "Kristen Chong is a 2D Illustrator/Animator based out of Toronto and is the brilliant creator and lead artist of the loved, iconic mushroom character, Mushy who can be seen all throughout social media.",
    links: [
      { name: "Portfolio", url: "https://kristenchong.editorx.io/portfolio" },
      {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/kristen-chong-42321a1b9/",
      },
    ],
  },
  {
    title: title.TechnologyAndMultimedia,
    fullName: "Brady Parent",
    copy: "Brady is a tech leader in the GTA and leads development for The World of Yasu and serves as tech advisor. Under his leadership, he's mentored several developers and mapped out product roadmaps.",
    links: [
      { name: "LinkedIn", url: "https://www.linkedin.com/in/bradyparent/" },
    ],
  },
  {
    title: title.TechnologyAndMultimedia,
    fullName: "Ömer Kaya",
    copy: "Ömer Kaya is a full-stack web developer based in the GTA  who was a critical team member in bringing the second iteration of the web application to life including complex components and stunning UX. ",
    links: [{ name: "LinkedIn", url: "https://www.linkedin.com/in/omrkaya/" }],
  },
  {
    title: title.TechnologyAndMultimedia,
    fullName: "Adlai B.",
    copy: "Adlai is a junior developer on The World of Yasu team, and has a valued growth mindset, navigating bugs and challenges with creative problem-solving and perseverance. ",
    links: [
      {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/adlai-bridson-boyczuk/",
      },
    ],
  },
  {
    title: title.TechnologyAndMultimedia,
    fullName: "Kyle Chaney",
    copy: "Kyle is a Toronto-based full-stack developer who spearheaded the initial build of The World of Yasu, meticulously selecting the ideal platform to bring the vision to life. ",
    links: [{ name: "LinkedIn", url: "https://www.linkedin.com/in/kpchaney/" }],
  },
  {
    title: title.DigitalAndStrategy,
    fullName: "Alicia Symons",
    copy: "Alicia is a content strategy and copy specialist based out of Toronto and  lead research and science for supplements for Health Canada and Formulation Labs while also leading content strategy for UX and social.",
    links: [
      { name: "Portfolio", url: "https://aliciasymons.com/" },
      {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/alicia-symons-0ab8b1169/",
      },
    ],
  },
  {
    title: title.DigitalAndStrategy,
    fullName: "Viraj G.",
    copy: "Viraj is a Toronto-based digital specialist, executed a TikTok growth hack during Yasu's early stages, generating millions of interactions and cultivating its first authentic community.",
    links: [
      {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/viraj-gulati-520261264/",
      },
    ],
  },
  {
    title: title.Artists,
    fullName: "Roy Scoop",
    copy: "Roy Scoop is a 2D Animator and concept artist based in Guatemala. Roy illustrates the social media posts on The World of Yasu and brilliantly animated The Sinkhole on YouTube.",
    links: [
      {
        name: "Art Station",
        url: "https://www.artstation.com/ryoheim",
      },
      {
        name: "Instagram",
        url: "https://www.instagram.com/ryo_heim/",
      },
      {
        name: "Vimeo",
        url: "https://vimeo.com/ryoheim",
      },
    ],
  },
];
