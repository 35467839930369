// function to abstract process.env when declare environment variables
const getEnvVar = (envVarName: string): string | undefined => {
    return process.env[envVarName];
}

// the app's environment variables declared as an object to reduce liklihood of typos or other errors when trying to access an environment variables. NOTE: If you add another env var to the app, please declare it here
export const ENV_VARS = {
    REACT_APP_ENV: getEnvVar('REACT_APP_ENV'),
    REACT_APP_FIREBASE_API_KEY: getEnvVar('REACT_APP_FIREBASE_API_KEY'),
    REACT_APP_FIREBASE_AUTH_DOMAIN: getEnvVar('REACT_APP_FIREBASE_AUTH_DOMAIN'),
    REACT_APP_FIREBASE_PROJECT_ID: getEnvVar('REACT_APP_FIREBASE_PROJECT_ID'),
    REACT_APP_FIREBASE_STORAGE_BUCKET: getEnvVar('REACT_APP_FIREBASE_STORAGE_BUCKET'),
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: getEnvVar('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
    REACT_APP_FIREBASE_APP_ID: getEnvVar('REACT_APP_FIREBASE_APP_ID'),
    REACT_APP_FIREBASE_MEASUREMENT_ID: getEnvVar('REACT_APP_FIREBASE_MEASUREMENT_ID'),
    REACT_APP_FIREBASE_APPCHECK_TOKEN: getEnvVar('REACT_APP_FIREBASE_APPCHECK_TOKEN') || '',
    REACT_APP_FIREBASE_USE_DEBUG_APPCHECK_TOKEN: getEnvVar('REACT_APP_FIREBASE_USE_DEBUG_APPCHECK_TOKEN') === 'true', // expected boolean in env
    REACT_APP_USE_EMULATORS: getEnvVar('REACT_APP_USE_EMULATORS') === 'true', // expected boolean in env
    REACT_APP_IP_ADDRESS: getEnvVar('REACT_APP_IP_ADDRESS') || 'localhost',
};