import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';

// factory to create custom Transitions that can be used with components such as Dialogs, Snackbars, Tooltips, Poppers, etc. See more https://mui.com/material-ui/transitions/#transitioncomponent-prop
const createTransition = (
    direction: 'left' | 'right' | 'up' | 'down'
) => forwardRef<unknown, TransitionProps & { children: React.ReactElement }>((
    props,
    ref
) => {
    return <Slide direction={direction} ref={ref} {...props} />;
});

// expose created transitions
export const RightSlideTransition = createTransition('right');