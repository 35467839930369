import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { Ref } from "react";

type CustomButtonProps = {
  isLoading?: boolean;
} & ButtonProps;

/**
 * Wrapper over MUI buttons that provides custom button styles including font, rounded edges, sizes on various screens, optional loading icon, and more
 */
const PillButton = <C extends React.ElementType>({
  isLoading = false,
  className = "",
  children,
  ref,
  variant = "contained",
  ...restOfProps
}: ButtonProps<C, { component?: C; ref?: Ref<HTMLButtonElement> }> &
  CustomButtonProps) => (
  <Button
    ref={ref}
    variant={variant}
    className={`YasuLibPillButton capitalize rounded-2xl font-quattro font-bold text-sm bg-primary sm:text-xl w-full sm:h-14 p-2 ${className}
    ${
      variant === "contained"
        ? "border-solid border-2 border-primary hover:bg-transparent"
        : ""
    }
    `}
    {...restOfProps}
  >
    {isLoading && (
      <CircularProgress className="text-white max-sm:!h-4 max-sm:!w-4 !h-6 !w-6 mx-2" />
    )}{" "}
    {children}
  </Button>
);

export default PillButton;
