import { Outlet, ScrollRestoration } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import AmbientMusic from "./AmbientMusic/AmbientMusic";
import Footer from "./Footer/Footer";
import { FC } from "react";
import useShowChoosePath from "../../context/ShowChoosePathProvider/useShowChoosePath";
import { isInitialRouteSiteRoot } from "../../utils/utils";

const Layout: FC<{ hideFooter?: boolean }> = ({ hideFooter = false }) => {
  const { showChoosePath } = useShowChoosePath();

  return (
    <>
      {!showChoosePath && (
        <Navbar className={`${isInitialRouteSiteRoot ? "fade-in" : ""}`} />
      )}
      <div className="max-sm:bg-midnight">
        <Outlet />
        <ScrollRestoration />
      </div>
      {!hideFooter && <Footer />}
      <AmbientMusic />
    </>
  );
};

export default Layout;
