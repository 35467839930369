import "./App.scss";
import CustomMUITheme from "./components/global/CustomMUITheme";
import Routing from "./components/global/Routing";
import AmbientMusicProvider from "./context/AmbientMusicProvider/AmbientMusicProvider";
import AuthProvider from "./context/AuthProvider/AuthProvider";
import FirebaseProvider from "./context/FirebaseProvider/FirebaseProvider";
import ShowAnimationProvider from "./context/ShowAnimationProvider/ShowAnimationProvider";
import ShowChoosePathProvider from "./context/ShowChoosePathProvider/ShowChoosePathProvider";

function App() {
  return (
    <FirebaseProvider>
      <AuthProvider>
        <ShowChoosePathProvider>
          <ShowAnimationProvider>
            <AmbientMusicProvider>
              <CustomMUITheme>
                <Routing />
              </CustomMUITheme>
            </AmbientMusicProvider>
          </ShowAnimationProvider>
        </ShowChoosePathProvider>
      </AuthProvider>
    </FirebaseProvider>
  );
}

export default App;
