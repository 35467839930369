import { useContext } from "react";
import {
  IShowChoosePathContext,
  ShowChoosePathContext,
} from "./ShowChoosePathProvider";

// light wrapper around AnimationCompleteProvider so we can use it as a custom hook
const useChoosePath = () => {
  return useContext(ShowChoosePathContext) as IShowChoosePathContext;
};

export default useChoosePath;
