import { Stack, Typography } from "@mui/material";
import Hero from "../../../shared/YasuLib/Hero/Hero";
import HeroContent from "../../../shared/YasuLib/Hero/HeroContent/HeroContent";
import HeroText from "../../../shared/YasuLib/Hero/HeroText/HeroText";
import HeroTitle from "../../../shared/YasuLib/Hero/HeroTitle/HeroTitle";
import MainContent from "../../../shared/YasuLib/MainContent/MainContent";
import PillButton from "../../../shared/YasuLib/PillButton/PillButton";
import "./Sinkhole.scss";
import NewsletterSection from "../../../shared/NewsletterSection/NewsletterSection";
import { useRef } from "react";

const Sinkhole = () => {
  const newsletterRef = useRef<null | HTMLDivElement>(null);

  // scroll via javascript because we can easily do smooth scrolling and
  // on page refresh we show the animation so preserving navigation anchor in URL
  // doesn't actually work because the page can't be rendered anywhere except the top
  const scrollToNewsletter = () => {
    newsletterRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="w-full">
      <Hero className="HomeHero w-full">
        <HeroContent>
          <HeroTitle>Welcome to The Sinkhole</HeroTitle>
          <HeroText>
            The World of Yasu intertwines a fantasy world with a beverage;
            Evelina's sorbet which she crafted in The Sinkhole. The Sinkhole is
            a fantasy fiction being released in late 2024.
          </HeroText>

          <PillButton
            onClick={scrollToNewsletter}
            className="max-sm:w-28 sm:w-44 rounded-3xl sm:h-12 !h-fit"
          >
            Subscribe
          </PillButton>
        </HeroContent>
      </Hero>
      <MainContent className="bg-midnightDark">
        <div className="max-w-5xl mx-auto">
          <Stack>
            <div className="hidden sm:block h-2 w-1/3 mt-24 bg-primary"></div>
            <Typography
              variant="h2"
              className="pt-8 pb-4 sm:py-12 text-xl sm:text-4xl font-bold"
            >
              Synopsis of The Sinkhole
            </Typography>
            <Typography
              variant="body2"
              className="text-base max-sm:leading-8 sm:text-lg font-quattro"
            >
              Two years after her younger sister’s tragic death in the Ordinary
              World, twenty-eight-year-old Evelina, overwhelmed by grief and
              emotions, finds herself warped into the unusual world of Yasu.
              Discovering her sister also died in Yasu from a lethal smoke
              marking, Evelina seeks to unravel what binds these two worlds
              together. In Yasu, Evelina's natural empath nature and high
              intuition are amplified, drawing attention and expectations from
              all corners. She is compelled to apply for leadership at the
              Academy, where she must complete two intense challenges that test
              her emotional resilience.
            </Typography>
            <Typography
              variant="body2"
              className="text-base max-sm:leading-8 sm:text-lg pt-4 sm:pt-8 font-quattro"
            >
              As she navigates these challenges and grapples with a disorder
              that leaves her physically weakened and emotionally drained for
              half the month, Evelina learns that the lethal smoke markings are
              back, and a larger smoke war looms on the horizon. The sudden
              appearance of the dangerous Drayven Vale, whose mysterious past
              may hold the key to her sister's death, intensifies her emotions
              in the escalating crisis.
            </Typography>
            <Typography
              variant="body2"
              className="text-base max-sm:leading-8 sm:text-lg pt-4 sm:pt-8 font-quattro"
            >
              Faced with the imminent collapse of both worlds, Evelina embarks
              on a desperate quest to decipher the cryptic clues her sister left
              behind. This journey not only forces her into a dangerous path but
              also challenges her to question the very nature of her reality. In
              a race against time, Evelina's search for answers becomes a fight
              for survival, as the fate of both the Ordinary World and Yasu
              hangs in her hands.
            </Typography>
          </Stack>
          <div className=" py-12 sm:px-18 text-center">
            <Typography
              variant="h2"
              className="py-4 sm:py-8 text-xl sm:text-4xl font-bold"
            >
              Released Story Artwork
            </Typography>

            <iframe
              className="w-full h-full max-w-2xl aspect-video"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/x9GJmn1WQB8?si=XNF6i7YMIYYszaFU"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div>
            <Typography
              variant="h2"
              className="py-4 sm:py-8 text-xl sm:text-4xl font-bold"
            >
              Immerse Yourself in The Sinkhole
            </Typography>
            <Typography
              variant="body1"
              className="text-base max-sm:leading-8 sm:text-lg font-quattro"
            >
              In The Sinkhole, you can hear whispers of unseen creatures echoing
              through the caverns. The air is crisp with the enticing aroma of
              honey, vanilla, and freshly baked sweet bread, mingling with the
              inviting scent of cozy spices wafting from The Market. Ingredients
              and herbs can be found here to rejuvenate your senses and ignite
              the imagination.
            </Typography>
            <Typography
              variant="body1"
              className="text-base max-sm:leading-8 sm:text-lg pt-4 sm:pt-8 font-quattro"
            >
              It's the home of the Vishuddha Community, one of the four unique
              communities nestled within the realm of Yasu. At the heart of The
              Sinkhole lies The Closet, a vibrant and bustling suburb bursting
              with color and life. Here, artisans and craftsmen showcase their
              talents, crafting enchanting fashion pieces and formidable
              defensive gear. The shelves are stocked with an eclectic array of
              materials, each more exotic and fantastical than the last,
              offering endless possibilities for innovation and expression.
            </Typography>
          </div>
        </div>
        <div id="newsletter" ref={newsletterRef}>
          <NewsletterSection
            heading="Follow The Sinkhole"
            text="Join our email list for exclusive monthly updates on drink launches, environment artwork, discoveries and more. "
          />
        </div>
      </MainContent>
    </div>
  );
};

export default Sinkhole;
