import { Link, Stack, Typography } from "@mui/material";
import "./Card.scss";
import { Creator } from "../Creators.data";
import { FC } from "react";

const Card: FC<Creator> = ({ fullName = "", copy = "", links }) => {
  return (
    <Stack>
      {/** Full name is added here */}
      <Typography
        variant="h3"
        className="text-left font-bold my-4 text-lg md:text-2xl"
      >
        {fullName}
      </Typography>
      {/** Copy is added here */}
      <Typography
        variant="body1"
        className="font-quattro text-base sm:text-lg space-y-8 sm:space-y-12 leading-8"
        gutterBottom
      >
        {copy}
      </Typography>
      <Stack
        className="flex flex-wrap text-primary text-base sm:text-lg"
        direction="row"
        spacing={0.5}
      >
        <Typography variant="subtitle1" className="font-quattro">
          Links:
        </Typography>
        {/** Links for creator object added here */}
        {links.map((link, index) => (
          <Typography variant="subtitle1" key={index}>
            {/** href added here */}
            <Link
              className="font-quattro"
              target="_blank"
              rel="noreferrer"
              href={link.url}
            >
              {/** name added here */}
              {link.name} {index === links.length - 1 ? "" : ","}
            </Link>
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

export default Card;
