import { useState, useEffect, FC, ReactElement } from 'react';
import './FadingTextAnimation.scss';
import { delay } from '../../../../../../utils/utils';
import { GenericProps } from '../../../../../../types/types';

export interface FadingTextAnimationItem {
    text: ReactElement;
    duration: number; // in milliseconds
}

type FadingTextAnimationProps = {
    textItems: FadingTextAnimationItem[],
    onAnimationComplete?: Function

} & GenericProps


/**
 * Movie-style sequence of fading text animations
 */
const FadingTextAnimation: FC<FadingTextAnimationProps> = ({ className = '', textItems, onAnimationComplete }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        // get the current text item from the array
        const currentTextItem = textItems[currentIndex];

        // create a delay response for the current item's duration
        // this is a custom alternative to wrapping delays in a setTimeout
        // to use async/await syntax and avoid nested callbacks
        const delayResponse = delay(currentTextItem.duration);

        // handle animated text change
        const handleTextChange = async () => {
            // extract delay promise and wait
            const { promise: delayPromise } = delayResponse;
            await delayPromise;

            // if not at last text item then move to next index
            if (currentIndex < textItems.length - 1) {
                setCurrentIndex(prevIndex => prevIndex + 1);
            } else {
                if (onAnimationComplete) onAnimationComplete();
            }
        };

        // initiate animation process
        // because this useEffect updates the currentIndex it uses as a dependency 
        // it will re-run until the last array element is processed
        handleTextChange();

        // cleanup on unmount
        return () => {
            // cancel the delay promise if it exists
            if (delayResponse) {
                delayResponse.cancel();
            }
        };
    }, [currentIndex, textItems, onAnimationComplete]);

    return (
        <div className={`FadingTextAnimation ${className}`}>
            <div className='fading-text-container relative'>
                {textItems.map((item, index) => (
                    <div key={index}
                        className={`fading-text w-full m-0 px-4 sm:px-12 text-2xl sm:text-4xl sm:!leading-[3rem] opacity-0 ${index === currentIndex ? 'visible' : 'hidden'}`}>
                        {item.text}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FadingTextAnimation;
