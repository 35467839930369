import { FC } from 'react';
import { GenericProps } from '../../../../../types/types';

/**
 * Contains styling for hero content including center alignment, margins,
 * and spacing between each item on the hero
 */
const HeroContent: FC<GenericProps> = ({ children, className = '' }) => {
    return (
        <div className={`YasuLibHeroContent h-full flex flex-col justify-center items-center text-center gap-y-5 sm:gap-y-9 max-w-5xl mx-auto max-[350px]:px-1 px-4 ${className}`}>
            {children}
        </div>
    );
}

export default HeroContent;