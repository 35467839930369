import { PauseCircle, PlayCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import useAmbientMusic from '../../../../../../context/AmbientMusicProvider/useAmbientMusic';
import { FC } from 'react';
import { GenericProps } from '../../../../../../types/types';

const MusicControls: FC<GenericProps> = ({ className = '' }) => {
    const { isPlaying, togglePlay, showControls } = useAmbientMusic();

    return (
        <>
            {showControls && (
                <div className={`${className}`}>
                    <Button onClick={() => togglePlay()}
                        className='animate-pulse-twice text-white normal-case h-12 min-w-[16rem] bg-primary bg-opacity-30 hover:bg-opacity-50 rounded-full p-5 flex justify-start'
                        aria-label="play/pause music" size="large"
                        startIcon={isPlaying ? <PauseCircle className='h-7 w-7 m-0' /> : <PlayCircle className='h-7 w-7 m-0' />}
                    >
                        <span>{isPlaying ? 'Pause' : 'Play'} the sounds of Yasu</span>
                    </Button>
                </div>
            )}
        </>
    );
}

export default MusicControls;