import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Layout from "./Layout";

// Pages
import Home from "../pages/Home/Home";
import Creators from "../pages/Creators/Creators";
import Privacy from "../pages/Legal/PrivacyPolicy/Privacy";
import WorldBuilding from "../pages/WorldBuilding/WorldBuilding";
import Explore from "../pages/ExploreTheWorld/Explore/Explore";
import TermsAndConditions from "../pages/Legal/TermsAndConditions/TermsAndConditions";

const Routing = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/explore-world",
          element: <Explore />,
        },
        {
          path: "terms",
          element: <TermsAndConditions />,
        },
        {
          path: "privacy",
          element: <Privacy />,
        },
        {
          path: "world-building",
          element: <WorldBuilding />,
        },
        {
          path: "creators",
          element: <Creators />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routing;
