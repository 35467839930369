import { FC } from 'react';
import { GenericProps } from '../../../../types/types';

/**
 * Contains bare minum skeleton of all Yasu hero elements:
 * 1. offset by navbar
 * 2. be full screen on 'sm' devices and higher
 */
const Hero: FC<GenericProps> = ({ children, className = '' }) => {
    return (
        <div className={`YasuLibHero sm:h-screen py-[96px] ${className}`}>
            {children}
        </div>
    );
}

export default Hero;