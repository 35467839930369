import './Privacy.scss';
import HeroContent from '../../../shared/YasuLib/Hero/HeroContent/HeroContent';
import HeroTitle from '../../../shared/YasuLib/Hero/HeroTitle/HeroTitle';
import Container from '@mui/material/Container';
import MainContent from '../../../shared/YasuLib/MainContent/MainContent';
import Hero from '../../../shared/YasuLib/Hero/Hero';

const Privacy = () => {
    return (
        <div className='bg-midnightDark'>
            <Hero className='!h-fit sm:pt-48'>
                <HeroContent>
                    <HeroTitle>Privacy Policy</HeroTitle>
                </HeroContent>
            </Hero>
            <Container>
                <MainContent className='pt-10 pb-32 font-quattro font-normal sm:text-xl leading-8 sm:leading-10'>
                    <h2 className='text-xl font-bold mb-10'>Introduction</h2>
                    <p>
                        This Privacy Policy outlines the handling practices for personal data collected by The World of Yasu Inc.through
                        the website theworldofyasu.com.
                    </p>
                    <h2 className='text-xl font-bold my-10'>Data Collection & Usage</h2>
                    <p>
                        <strong>Comments:</strong> When you leave comments, we collect the data shown in the form, your IP address, and browser
                        user agent to facilitate spam detection.
                    </p>
                    <p>
                        <strong>Media:</strong> Images with embedded location data(EXIF GPS) should not be uploaded.Location data can be downloaded
                        and extracted by visitors from such images.
                    </p>
                    <p>
                        <strong>Cookies:</strong> To enhance your experience, we use cookies to remember your details for future comments, to manage
                        session information when you log in, and to track user preferences.
                    </p>
                    <p>
                        <strong>Embedded Content:</strong> Articles on this site may include embedded content from other websites.These websites
                        may collect data about you, use cookies, and monitor your interaction with embedded content.
                    </p>
                    <h2 className='text-xl font-bold my-10'> Data Sharing & Retention </h2>
                    <p>
                        <strong>Comments:</strong> To recognize and approve follow - up comments, we retain comment data indefinitely.
                    </p>
                    <p>
                        <strong>User Accounts:</strong> Information provided by registered users is stored in their user profiles and can be accessed,
                        edited, or deleted at any time.Username changes are not permitted.
                    </p>
                    <p>
                        <strong>Analytics:</strong> We may share anonymized data with third - party analytics providers to understand website usage patterns.
                    </p>
                    <p>
                        <strong>Analytics:</strong> We may share anonymized data with third - party analytics providers to understand website usage patterns.
                    </p>
                    <h2 className='text-xl font-bold my-10'>
                        Your Rights
                    </h2>
                    <p>
                        <strong>Access & Portability:</strong> You can request a copy of the personal data we hold about you.
                    </p>
                    <p>
                        <strong>Erasure:</strong> You can request that we erase any personal data we hold about you, subject to our retention policies.
                    </p>
                    <p>
                        <strong>Rectification:</strong> If the information we hold about you is inaccurate or incomplete, you have the right to ask us to correct it.
                    </p>
                    <h2 className='text-xl font-bold my-10'>Data Protection</h2>
                    <p>
                        <strong>Security Measures:</strong> We implement security measures to protect against unauthorized access, alteration, disclosure,
                        or destruction of your personal data.
                    </p>
                    <p>
                        <strong>Data Breaches:</strong> In the event of a data breach, we have procedures in place to mitigate the effects and will notify
                        you and any applicable regulators where we are legally required to do so.
                    </p>
                    <p>
                        <strong>Third - Party Services:</strong> We do not sell, trade, or rent users' identification information to others. We may share
                        generic aggregated demographic information not linked to any personal identification information regarding visitors and users
                        with our business partners, trusted affiliates, and advertisers.
                    </p>
                    <p>
                        <strong>Regulatory Compliance:</strong> We comply with industry - standard privacy regulations and are committed to upholding the
                        confidentiality and privacy of our users' data.
                    </p>
                    <h2 className='text-xl font-bold my-10'>Contact Us</h2>
                    <p>
                        For any questions regarding this Privacy Policy or the practices of this site, please contact us at hello @theworldofyasu.com.
                    </p>
                    <p>
                        This document was last updated on <strong>November 1, 2023</strong>. Please review it periodically, as we reserve the right to modify our privacy practices and this Privacy Policy.
                    </p>
                </MainContent>
            </Container>
        </div>
    )
}

export default Privacy

