import './WorldBuilding.scss';
import HeroContent from '../../shared/YasuLib/Hero/HeroContent/HeroContent';
import HeroTitle from '../../shared/YasuLib/Hero/HeroTitle/HeroTitle';
import HeroText from '../../shared/YasuLib/Hero/HeroText/HeroText';
import { Container, Grid, Link, Stack, Typography } from '@mui/material';
import MainContent from '../../shared/YasuLib/MainContent/MainContent';
import Hero from '../../shared/YasuLib/Hero/Hero';

import characterImage from '../../../assets/images/WorldBuilding/character.png';
import statsImage from '../../../assets/images/WorldBuilding/stats.png';
import NewsletterForm from '../../shared/NewsletterSection/NewsletterForm/NewsletterForm';

const WorldBuilding = () => {

    return (
        <div className='WorldBuilding'>
            {/** Title section */}
            <Hero className='!h-fit sm:pt-48 bg-black'>
                <HeroContent className='max-w-fit'>
                    <HeroTitle>
                        World Building
                    </HeroTitle>
                    <HeroText>
                        Bridge the fantasy and reality in your mind. Enrich and brighten the world that surrounds you.
                    </HeroText>
                </HeroContent>
            </Hero>
            <MainContent className='bg-midnightDark'>
                <Stack className='pt-20 max-w-7xl mx-auto' spacing={{ md: 12 }} direction={{ sm: "column", md: "row" }}>
                    <Stack className='sm:w-full md:w-1/2 pb-8 md:pb-4'>
                        <Container className='px-0'>
                            <img className='w-full' src={characterImage} alt='character builder' />
                            <Typography variant="h2" className='text-left font-bold my-4 text-lg md:text-2xl'>Character Creation</Typography>
                            <Typography className='text-sm leading-7 mb-4'>
                                Download these 4 free character archetype slides. Feel free to alter each template to make it your own. For using slides, upload ppt. into google drive before editing.
                            </Typography>
                            <Link target='_blank' rel='noreferrer' href='https://docs.google.com/presentation/d/1zUYInZ4mgtYSOJZKQuJDGQ5QXeYZDSRV/'>
                                <Typography className='text-left text-sm mb-4'>Click to view slides</Typography>
                            </Link>
                        </Container>
                    </Stack>
                    <Stack className='sm:w-full md:w-1/2'>
                        <Container className='px-0'>
                            <img className='w-full' src={statsImage} alt='stats builder' />
                            <Typography variant="h2" className='text-left font-bold my-4 text-lg md:text-2xl'>Character Creation</Typography>
                            <Typography className='text-sm leading-7 mb-4'>
                                Download this emotional intelligence slider for free along with the definitions for each. Feel free to alter each template to make it your own. For using slides, upload ppt. into google drive before editing.
                            </Typography>
                            <Link target='_blank' rel='noreferrer' href="https://docs.google.com/presentation/d/1eAi4RDHvmNwHrPj81awXUZdKZ992bNwP/">
                                <Typography className='text-left text-sm mb-4'>Click to view slides</Typography>
                            </Link>
                        </Container>
                    </Stack>
                </Stack>

                <div className='max-w-screen-2xl w-full md:w-5/6 mx-auto py-8 pb-20 py-4 sm:py-40'>
                    <Grid container spacing={{ xs: 1 }}>
                        <Grid item xs={12}>
                            <Typography variant="h2" className='text-left text-xl sm:text-4xl my-0 sm:mb-5 font-bold sm:font-medium'>
                                Exclusive Content Access
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={7}>
                            <Stack className='text-left'>
                                <Typography className='font-quattro text-base sm:text-2xl leading-8 sm:leading-10'>
                                    Never miss a new discovery in Yasu. Those who are on our email list will be given early exclusive access to both Yasu art, and world building content.
                                </Typography>
                            </Stack>
                            <Stack className='my-5'>
                                <div className='hidden sm:block h-1 w-24 bg-primary'></div>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} lg={5}>
                            <NewsletterForm className='sm:p-4 sm:m-4' />
                        </Grid>
                    </Grid>

                    <Typography variant='body2' className='text-left mt-12 text-gray-400'>
                        Do not redistribute or modify any items from The World of Yasu Inc. for stock, tools, templates, or source files. Prohibited in both free and paid forms, individually or bundled. No derivatives of fonts or add-ons. No claiming of trademark, service mark, or ownership rights. Original ownership persists. By downloading resources from The World of Yasu Inc., you accept these terms.
                    </Typography>
                </div>
            </MainContent>
        </div >
    );
}

export default WorldBuilding;
