import ReactPlayer from 'react-player';
import { Fab, Tooltip } from '@mui/material';
import { MusicNote, MusicOff } from '@mui/icons-material';
import AmbientMusicMP3 from '../../../assets/music/ambient.mp3';
import useAmbientMusic from '../../../context/AmbientMusicProvider/useAmbientMusic';

// NOTE this is tied to AmbientMusicProvider for state management
// state management is not done directly in this component so it can also be controled by the Yasu animation component
const AmbientMusic = () => {
    const { isPlaying, togglePlay, showControls, setShowControls } = useAmbientMusic();

    // music has ended so hide music controls
    const musicEnded = () => {
        setShowControls(false);
    }

    return (
        <>
            <ReactPlayer
                url={AmbientMusicMP3}
                onEnded={musicEnded}
                playing={isPlaying} playsinline height={0} width={0} />
            {/* only visible if global show controls flag is set to true */}
            {showControls && (
                <Tooltip title={`${isPlaying ? 'Pause' : 'Play'} music`}>
                    {/* z-10 sets z index so we can reliably hide global button under other elements if desired (ie under the Yasu animation) */}
                    <Fab onClick={() => togglePlay()}
                        className='fixed bottom-6 right-6 md:bottom-12 md:right-20 z-10'
                        size='small'
                        color='primary'
                        aria-label='play/pause ambient music'>
                        {isPlaying ? (
                            <MusicOff />
                        ) : (
                            <MusicNote />
                        )}
                    </Fab>
                </Tooltip>
            )}
        </>
    );
}

export default AmbientMusic;