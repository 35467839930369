import { FC } from 'react';
import { GenericProps } from '../../../../../../types/types';

type TypeTextProps = {
    text: string;
    labelClassName: string;
} & GenericProps;

const TypeText: FC<TypeTextProps> = ({ text, labelClassName }) => {
    return (
        <div className='SupplementTypeText flex items-center'>
            <span className={`h-3.5 w-14 mr-2 ${labelClassName}`}></span>{text}
        </div>
    );
}

export default TypeText;