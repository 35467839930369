/**
 * FORM VALIDATORS FOR REACT HOOK FORMS
 */
export const emailValidator = {
    required: 'Email is required',
    minLength: {
        value: 6,
        message: 'Email must have at least 6 characters',
    },
    maxLength: {
        value: 99,
        message: 'Email must not exceed 99 characters',
    },
    pattern: {
        value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        message: 'Invalid email address format',
    },
}