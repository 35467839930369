import { FC } from 'react';
import { GenericProps } from '../../../../types/types';
import CustomMUITheme from '../../../global/CustomMUITheme';

// Only to be used for full-site non recoverable errors. Something really bad must've happened. DO NOT USE OTHERWISE
const FatalError: FC<GenericProps> = ({ className }) => {
    return (
        // wrapped with CustomMUITheme since this component is generally used OUTSIDE the scope of the App theme (ie in the Firebase, Auth, or other high level providers above the theme)
        <CustomMUITheme>
            <div className={`FatalError p-2 h-screen w-full flex items-center justify-center ${className}`}>
                <h1 className='m-0 text-white'>Oh no! A fatal error has occured. Please contact us if it persists.</h1>
            </div>
        </CustomMUITheme>
    );
}

export default FatalError;