/**
 * Add a time delay using async/await notation instead of setTimeout nested callbacks
 * @param ms Delay in milliseconds
 * @returns Promise that can be awaited
 * @returns Cancel function to cancel the delay and prevent memory leaks
 */
export const delay = (ms: number) => {
  let timeoutId: NodeJS.Timeout;

  const promise = new Promise((resolve) => {
    timeoutId = setTimeout(resolve, ms);
  });

  const cancel = () => {
    clearTimeout(timeoutId);
  };

  return { promise, cancel };
};

export const isInitialRouteSiteRoot = window.location.pathname === "/";
