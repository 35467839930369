import "./Home.scss";
import { NavLink } from "react-router-dom";
import Hero from "../../shared/YasuLib/Hero/Hero";
import HeroContent from "../../shared/YasuLib/Hero/HeroContent/HeroContent";
import HeroTitle from "../../shared/YasuLib/Hero/HeroTitle/HeroTitle";
import PillButton from "../../shared/YasuLib/PillButton/PillButton";
import { Container, Stack } from "@mui/material";
import { AnimationEvent, useRef, useState } from "react";
import NewsletterSection from "../../shared/NewsletterSection/NewsletterSection";
import MainContent from "../../shared/YasuLib/MainContent/MainContent";
import YasuEnergyDrinkDesktop from "../../../assets/images/YasuEnergyDrinkDesktop.png";
import YasuEnergyDrinkMobile from "../../../assets/images/YasuEnergyDrinkMobile.png";

import SupplementTable from "./SupplementTable/SupplementTable";
import useChoosePath from "../../../context/ShowChoosePathProvider/useShowChoosePath";
import useAmbientMusic from "../../../context/AmbientMusicProvider/useAmbientMusic";

const Home = () => {
  const [fadeOutChoosePath, setFadeOutChoosePath] = useState(false);
  const { showChoosePath, setShowChoosePath } = useChoosePath();
  const { togglePlay } = useAmbientMusic();

  const newsletterRef = useRef<null | HTMLDivElement>(null);

  // scroll via javascript because we can easily do smooth scrolling and
  // on page refresh we show the animation so preserving navigation anchor in URL
  // doesn't actually work because the page can't be rendered anywhere except the top
  const scrollToNewsletter = () => {
    newsletterRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  /**
   * For triggering the fade-out of animation section and
   * fade-in of other site content
   */
  const handleExploreDrink = () => {
    setFadeOutChoosePath(true);

    // scroll to top of page to make sure user and navbar
    // are positioned at the top after animation
    window.scrollTo(0, 0);
  };

  const handleExploreWorld = () => {
    togglePlay();
    setShowChoosePath(false);
  };

  /**
   * For removing the section from the DOM after it has faded out.
   * When the fade-out is complete, update global state
   * so it isn't shown again on this page (unless the user refreshes, of course)
   */
  const handleAnimationEnd = (e: AnimationEvent<HTMLDivElement>) => {
    // check only for the animation and element we want and ignore the others
    if (
      (e.target as HTMLDivElement).id === "HomeChoosePath" &&
      e.animationName === "fadeOut"
    ) {
      setShowChoosePath(false);
    }
  };

  return (
    <div className="Home h-full">
      {/* Choose Path */}
      {/* z-20 sets z index higher than the AmbientMusic global FAB (z-10) so that the animation is displayed on top. Only apply z-20 until fade-out class is applied so the faded out animation does not prevent clicking on the FAB. While waiting for animation to load, bg-midnight sets background colour so it is not transparent and hides elements underneath (ie FAB ambient music) */}
      {showChoosePath && (
        <div
          id="HomeChoosePath"
          className={`ChoosePath fixed w-full h-screen bg-midnight ${
            fadeOutChoosePath ? "fade-out" : "z-20"
          }`}
          onAnimationEnd={handleAnimationEnd}
        >
          <Hero className={`ChoosePathHero h-screen`}>
            <HeroContent>
              <HeroTitle className="md:mb-5">Choose your path</HeroTitle>

              <Stack direction={"row"} spacing={{ xs: 3, lg: 7 }}>
                <PillButton
                  className="max-sm:w-28 sm:w-44 rounded-3xl sm:h-12 !bg-primary !bg-opacity-60 hover:!bg-opacity-40 !border-0 !h-fit"
                  onClick={handleExploreDrink}
                >
                  Explore Drink
                </PillButton>
                <PillButton
                  className="max-sm:w-28 sm:w-44 rounded-3xl sm:h-12 !bg-primary !bg-opacity-60 hover:!bg-opacity-40 !border-0 !h-fit"
                  component={NavLink}
                  onClick={handleExploreWorld}
                  to="/explore-world"
                >
                  Explore World
                </PillButton>
              </Stack>
            </HeroContent>
          </Hero>
        </div>
      )}
      {/* Page content */}
      <div className={`${fadeOutChoosePath ? "fade-in" : ""}`}>
        {/* Header */}
        <Hero className={`HomeHero`}>
          <HeroContent>
            <HeroTitle>
              A functional drink to restore your productivity
            </HeroTitle>
            <PillButton
              onClick={scrollToNewsletter}
              className="max-sm:w-28 sm:w-44 rounded-3xl sm:h-12 !h-fit"
            >
              Pre-Launch
            </PillButton>
          </HeroContent>
        </Hero>
        <MainContent
          className={`MainContent ${fadeOutChoosePath ? "fade-in" : ""}`}
        >
          <div className="max-w-5xl max-sm:pb-12 sm:mt-12 sm:mb-16 mx-auto font-quattro text-base sm:text-2xl space-y-8 sm:space-y-12 leading-8 sm:leading-10">
            <div className="hidden sm:block h-2 w-1/3 mt-4 mb-12 bg-primary"></div>
            {/* padding instead of the space-y-8 margin applied on mobile devices so we don't see the edge of the bg img */}
            <h2 className="!mt-0 max-sm:pt-12 font-poppins">
              Your mid-day pick-me-up
            </h2>
            <p className="mt-8">
              Yasu is a naturally sweetened, low caffeine beverage crafted with
              16 chosen ingredients to pick you up without the jitters.
            </p>
            <h2 className="font-poppins sm:pt-12">
              A blend with brain benefits
            </h2>
          </div>

          <div>
            <div className="relative text-center w-full sm:w-4/5 mx-auto px-1 sm:px-4 md:my-12 lg:mt-28 lg:mb-40">
              <img
                src={YasuEnergyDrinkDesktop}
                alt="Yasu Energy Drink"
                flex-shrink="0"
                className="w-full hidden md:block"
              />
              <img
                src={YasuEnergyDrinkMobile}
                alt="Yasu Energy Drink"
                flex-shrink="0"
                className="md:hidden px-20 w-full max-w-xs"
              />
              <div className="hidden md:flex flex-wrap justify-between absolute w-full h-full top-0">
                <div className="w-2/5 relative">
                  <Container className="pr-4 md:pr-24">
                    <h3 className="text-[1.6vw]"> L-Theanine</h3>
                    <p className="text-[1.2vw]">
                      Relaxes the mind without drowsiness, reduces anxiety, and
                      improves mood.
                    </p>
                  </Container>
                </div>
                <div className="w-2/5 relative">
                  <Container className="pl-12 md:pl-24">
                    <h3 className="text-[1.6vw]"> Magnesium</h3>
                    <p className="text-[1.2vw]">
                      Enables energy production and reduces cortisol, the body's
                      stress hormone.
                    </p>
                  </Container>
                </div>
                <div className="w-2/5 mt-auto mb-8 md:mb-12 relative">
                  <Container className="pr-12 md:pr-24">
                    <h3 className="text-[1.6vw]"> Vitamin B6</h3>
                    <p className="text-[1.2vw]">
                      Supports the growth of serotonin and dopamine, that
                      regulate mood in the brain.
                    </p>
                  </Container>
                </div>
                <div className="w-2/5 mt-auto mb-8 md:mb-12 relative">
                  <Container className="pl-12 md:pl-24">
                    <h3 className="text-[1.6vw]"> Day Lily</h3>
                    <p className="text-[1.2vw]">
                      Improves sleep, memory, and has antioxidants that protect
                      the immune system and brain.
                    </p>
                  </Container>
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-5xl max-sm:py-8 sm:mt-40 sm:mb-10 mx-auto font-quattro text-base sm:text-2xl space-y-8 sm:space-y-12 leading-8 sm:leading-10">
            <h2 className="font-poppins">Ingredients backed by science</h2>
            <p>
              Interact with our ingredient table to learn about the function
              each vitamin, mineral, and nootropic in Yasu Energy performs in
              the body.
            </p>
          </div>
          <div className="SupplementSection max-w-5xl m-auto">
            <SupplementTable />
          </div>

          <div id="newsletter" ref={newsletterRef}>
            <NewsletterSection />
          </div>
        </MainContent>
      </div>
    </div>
  );
};

export default Home;
