import { FC } from "react";
import { GenericProps } from "../../../../types/types";

const MainContent: FC<GenericProps> = ({ children, className = "" }) => {
  return (
    <div
      className={`YasuLibMainContent max-[350px]:px-1 px-4 md:px-12 max-sm:pt-0 ${className}`}
    >
      {children}
    </div>
  );
};

export default MainContent;
