import { FC, createContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { AppCheck, ReCaptchaEnterpriseProvider, initializeAppCheck } from 'firebase/app-check';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { FirebaseApp } from "firebase/app";
import { Auth } from "firebase/auth";
import { Functions } from "firebase/functions";
import { GenericProps } from '../../types/types';
import { ENV_VARS } from '../../utils/env';
import FatalError from '../../components/shared/YasuLib/FatalError/FatalError';

export interface IFirebaseContext {
    app: FirebaseApp;
    appCheck: AppCheck | null;
    auth: Auth;
    functions: Functions;
}

export const FirebaseContext = createContext<IFirebaseContext | null>(null);

// pulling from env so we can dynamically change environment (dev vs prod, etc.)
const firebaseConfig = {
    apiKey: ENV_VARS.REACT_APP_FIREBASE_API_KEY,
    authDomain: ENV_VARS.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: ENV_VARS.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: ENV_VARS.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: ENV_VARS.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: ENV_VARS.REACT_APP_FIREBASE_APP_ID,
    measurementId: ENV_VARS.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const FirebaseProvider: FC<GenericProps> = ({ children }) => {
    const [firebaseInitializing, setFirebaseInitializing] = useState(true);
    const [initializationFailed, setInitializationFailed] = useState(false);
    const [firebaseInstance, setFirebaseInstance] = useState<IFirebaseContext | null>(null);

    useEffect(() => {
        try {
            // Initialize Firebase
            const app = initializeApp(firebaseConfig);

            // will only exist on live build
            let appCheck: AppCheck | null = null;

            // initialize Firebase services used
            const auth = getAuth(app);
            const functions = getFunctions(app);

            // connect to emulators
            if (ENV_VARS.REACT_APP_USE_EMULATORS) {
                connectAuthEmulator(auth, `http://${ENV_VARS.REACT_APP_IP_ADDRESS}:9099`);
                connectFunctionsEmulator(functions, ENV_VARS.REACT_APP_IP_ADDRESS, 5001);
            } else {
                // configure debug appcheck token according to docs
                if (ENV_VARS.REACT_APP_FIREBASE_USE_DEBUG_APPCHECK_TOKEN) {
                    // eslint-disable-next-line no-restricted-globals
                    (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = ENV_VARS.REACT_APP_FIREBASE_APPCHECK_TOKEN;
                }

                // only initialize if NOT using emulators
                // initialize AppCheck --> for live environments only
                appCheck = initializeAppCheck(app, {
                    provider: new ReCaptchaEnterpriseProvider(ENV_VARS.REACT_APP_FIREBASE_APPCHECK_TOKEN),
                    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
                });
            }


            // Set the Firebase instances and indicate that Firebase is no longer initializing
            setFirebaseInstance({
                app,
                appCheck,
                auth,
                functions
            });
            setFirebaseInitializing(false);
        } catch (err) {
            console.error('Fatal error initializing Firebase ', err);
            setInitializationFailed(true);
        }
    }, []);

    // show fatal error if failed to initialize Firebase
    if (initializationFailed) {
        return <FatalError />;
    }

    // don't show anything while initializing
    // consider showing a loading page instead
    if (firebaseInitializing) {
        return <></>;
    }

    return (
        <FirebaseContext.Provider value={firebaseInstance}>
            {children}
        </FirebaseContext.Provider>
    );
}

export default FirebaseProvider;