import { FC } from 'react';
import { GenericProps } from '../../../../../types/types';

/**
 * Contains styling for hero content including center alignment, margins,
 * and spacing between each item on the hero
 */
const PopupHighlight: FC<GenericProps> = ({ children, className = '' }) => {
    return (
        <span className='text-yellowHighlight'>{children}</span>
    );
}

export default PopupHighlight;