import { Grid } from "@mui/material";
import NewsletterForm from "./NewsletterForm/NewsletterForm";
import { FC } from "react";
import { GenericProps } from "../../../types/types";

type NewsletterSectionProps = {
  heading?: string;
  text?: string;
} & GenericProps;

const NewsletterSection: FC<NewsletterSectionProps> = ({
  className = "",
  heading = "Get ready for launch",
  text = "Join our email list for early access to our soft launch, discounts, and exciting announcements. ",
}) => {
  return (
    <div
      className={`NewsletterSection max-w-screen-2xl mx-auto py-8 pb-20 sm:py-40 ${className}`}
    >
      <Grid container spacing={{ xs: 1 }}>
        <Grid item xs={12}>
          <h2 className="text-xl sm:text-4xl my-0 sm:mb-5 font-bold sm:font-medium">
            {heading}
          </h2>
          <div className="hidden sm:block h-1 w-24 bg-primary"></div>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          <div className="font-quattro text-base sm:text-2xl leading-8 sm:leading-10">
            <p>{text}</p>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <NewsletterForm className="sm:p-4 sm:m-4" />
        </Grid>
      </Grid>
    </div>
  );
};

export default NewsletterSection;
