import { FC } from 'react';
import { GenericProps } from '../../../../../../types/types';
import TypeText from '../TypeText/TypeText';

const FullLegend: FC<GenericProps> = ({ className = '' }) => {
    return (
        <div className={`SupplementLegend justify-center space-x-12 my-9 font-quattro font-bold ${className}`}>
            <div className='text-3xl'>
                Types
            </div>
            <div className='flex flex-col'>
                <TypeText text='Vitamins' labelClassName='bg-vitamin' />
                <TypeText text='Minerals' labelClassName='bg-mineral' />
            </div>
            <div className='flex flex-col'>
                <TypeText text='Amino Acids' labelClassName='bg-aminoAcid' />
                <TypeText text='Plant Family' labelClassName='bg-plantFamily' />
            </div>
        </div>
    );
}

export default FullLegend;