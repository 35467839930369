import './TermsAndConditions.scss';
import '../Legal.scss';
import HeroContent from '../../../shared/YasuLib/Hero/HeroContent/HeroContent';
import HeroTitle from '../../../shared/YasuLib/Hero/HeroTitle/HeroTitle';
import Container from '@mui/material/Container';
import MainContent from '../../../shared/YasuLib/MainContent/MainContent';
import Hero from '../../../shared/YasuLib/Hero/Hero';

const TermsAndConditions = () => {
    return (
        <div className='bg-midnightDark'>
            <Hero className='!h-fit sm:pt-48'>
                <HeroContent>
                    <HeroTitle>Terms and Conditions</HeroTitle>
                </HeroContent>
            </Hero>
            <Container>
                <MainContent className='pt-10 pb-32 font-quattro font-normal sm:text-xl leading-8 sm:leading-10'>
                    <h2 className='text-xl font-bold mb-10'>DISCLAIMER</h2>
                    <p>
                        The World of Yasu Inc. and those responsible for its website (“the Company”) strive to ensure the accuracy of the
                        information provided on this site. Discrepancies may exist between the actual product packaging and website
                        representations due to the product's pre-launch status. We caution against sole reliance on the site's content,
                        including any stated benefits of the Yasu Energy blend of supplements.
                    </p>
                    <p>
                        Consult healthcare professionals before using this information as a substitute for medical advice. This information
                        should not be used for self-diagnosis or treating health problems, disorders or illnesses, and no claims are made
                        that Yasu Energy can assist with health issues.
                    </p>
                    <p>
                        Acceptance of terms of use and amendments is mandatory when visiting the site. You agree to our terms, and the
                        Company reserves the right to modify or remove data at any time, disclaiming responsibility for the accuracy or
                        delivery of information.
                    </p>
                    <h2 className='text-xl font-bold my-10'>WHOLESALE TERMS AND CONDITIONS</h2>
                    <p>
                        <strong>Orders:</strong> Increases to any MOQ or bundle must be by at least 2-case increments.
                    </p>
                    <p>
                        <strong>Pricing:</strong> All prices are in CAD dollars and are subject to change without prior notice.
                    </p>
                    <p>
                        <strong>Pricing Policy:</strong> Advertising Yasu Energy products below the minimum advertised price is prohibited
                        without written approval. Non-compliance may lead to suspension of shipments.
                    </p>
                    <p>
                        <strong>Listings:</strong> Confirmation from our wholesale portal is accompanied by brand guidelines, including
                        product descriptions and copy.
                    </p>
                    <p>
                        <strong>Images:</strong> Use of our branded images is allowed but doesn't grant exclusive rights. The World of
                        Yasu Inc. reserves the right to terminate image usage privileges at any time.
                    </p>
                    <p>
                        <strong>Shipping & Delivery:</strong> We dispatch orders within 5 business days after processing. We aim for the
                        earliest possible delivery.
                    </p>
                    <p>
                        <strong>Cancellation:</strong> The right to cancel or not fulfill orders is reserved in cases of external
                        conflicts, brand image concerns, or non-payment of invoices.
                    </p>
                    <p>
                        <strong>Payment terms:</strong> Stipulated in the wholesale portal with processing through Stripe preferred.
                    </p>
                    <p>
                        <strong>Damage claims:</strong> Inspect deliveries immediately. Any damages must be reported to The World of
                        Yasu Inc. at once to be considered for a refund.
                    </p>
                    <p>
                        <strong>Refunds:</strong> If the damage is irreparable to a part of the shipped goods, The World of Yasu Inc.
                        may authorize a return, and will discuss return shipping costs.
                    </p>
                    <p>
                        <strong>Website:</strong> theworldofyasu.com
                    </p>
                </MainContent>
            </Container>
        </div>
    )
}

export default TermsAndConditions;
