import { FC, createContext, useState } from "react";
import { GenericProps } from "../../types/types";
import { isInitialRouteSiteRoot } from "../../utils/utils";

export interface IShowChoosePathContext {
  showChoosePath: boolean;
  setShowChoosePath: Function;
}

export const ShowChoosePathContext =
  createContext<IShowChoosePathContext | null>(null);

const ShowChoosePathProvider: FC<GenericProps> = ({ children }) => {
  // enable choose path if initial site route is the root route
  const [showChoosePath, setShowChoosePath] = useState(isInitialRouteSiteRoot);

  // provider values to expose
  const theValues = {
    showChoosePath,
    setShowChoosePath,
  };

  return (
    <ShowChoosePathContext.Provider value={theValues}>
      {children}
    </ShowChoosePathContext.Provider>
  );
};

export default ShowChoosePathProvider;
