import { InputHTMLAttributes, forwardRef } from 'react';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    // Add any custom props specific to your Input component
    errorMessage?: string;
}

// correct way to forward input's ref
const Input = forwardRef<HTMLInputElement, IInputProps>(({ errorMessage, className = '', ...restOfProps }, ref) => (
    <div className={`YasuLibInput flex flex-col ${className}`}>
        <input className={`h-10 border-none text-base px-2 rounded-2xl sm:h-14 sm:text-xl pl-5`} ref={ref} {...restOfProps} />
        <span className='InputErrorMessage text-red-600 max-sm:text-xs text-sm py-1 pl-3 h-7'>{errorMessage}</span>
    </div>
));

export default Input;




