import { FC } from 'react';
import { GenericProps } from '../../../../../types/types';
import { Typography } from '@mui/material';

/**
 * Styles and size of the hero title on varying screen sizes
 */
const HeroTitle: FC<GenericProps> = ({ children, className = '' }) => {
    return (
        <Typography variant='h1' className={`YasuLibHeroTitle text-3xl sm:text-7xl font-bold font-museo ${className}`}>{children}</Typography>
    );
}

export default HeroTitle;