import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { NavLink } from "react-router-dom";
import LogoWhite from "../../../assets/images/logo-white.png";
import "./Navbar.scss";
import MobileNavbar from "./MobileNavbar/MobileNavbar";
import { navItems } from "./navItems";
import { GenericProps } from "../../../types/types";
import { FC } from "react";

const Navbar: FC<GenericProps> = ({ className = "" }) => {
  return (
    <AppBar
      className={`NavbarCustom absolute top-0 left-0 w-full bg-transparent shadow-none z-50 py-4 ${className}`}
    >
      <Container>
        <Toolbar className="flex items-center justify-between max-md:px-0">
          {/* only show on small screen sizes */}
          <MobileNavbar className="md:hidden" />

          <div className="flex items-center">
            <NavLink
              to="/"
              className="max-md:hidden flex items-center no-underline"
            >
              <img src={LogoWhite} alt="Yasu logo" width={50} height={50} />
            </NavLink>
          </div>

          {/* only show on medium+ screen sizes */}
          <div className="max-md:hidden flex-grow flex items-center justify-center">
            {navItems.common.map((item) => (
              <NavLink
                key={item.label}
                to={item.path}
                className={({ isActive }) =>
                  `text-white text-lg mx-4 ${
                    isActive
                      ? "underline decoration-2 underline-offset-4"
                      : "no-underline"
                  }`
                }
              >
                {item.label}
              </NavLink>
            ))}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
