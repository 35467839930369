import { FC, useState } from "react";
import "./YasuAnimation.scss";
import { GenericProps } from "../../../../../types/types";
import ReactPlayer from "react-player";
import FadingTextAnimation, {
  FadingTextAnimationItem,
} from "./FadingTextAnimation/FadingTextAnimation";
import { Button, Typography } from "@mui/material";
import MusicControls from "./MusicControls/MusicControls";

// the animation text
const fadingTextAnimationArray: FadingTextAnimationItem[] = [
  {
    text: <p>“First, grief warped me to Yasu.</p>,
    duration: 3500,
  },
  {
    text: <p>Then, the quest to save two worlds.</p>,
    duration: 3500,
  },
  {
    text: (
      <p>
        And then the fight to save myself.“
        <br />- Evelina Woods
      </p>
    ),
    duration: 3000,
  },
];

type YasuAnimationProps = {
  onYasuAnimationComplete?: Function;
} & GenericProps;

/**
 * Yasu animation. onAnimationComplete prop fires after TEXT animation ends and ignores video
 */
const YasuAnimation: FC<YasuAnimationProps> = ({
  onYasuAnimationComplete,
  className = "",
}) => {
  const [isVideoStarted, setIsVideoStarted] = useState(false);

  const onVideoStart = () => {
    setIsVideoStarted(true);
  };

  const animationComplete = () => {
    // send to on complete callback if the prop was passed
    if (onYasuAnimationComplete) {
      onYasuAnimationComplete();
    }
  };

  return (
    <div
      className={`YasuAnimation h-full w-full overflow-hidden bg-midnight ${className}`}
    >
      <div className="relative h-full w-full">
        <div
          className={`YasuAnimationVideoContainer h-full w-full ${
            isVideoStarted ? "" : "invisible"
          }`}
        >
          <ReactPlayer
            url="https://www.dropbox.com/scl/fi/x9hl646rhsa9iqwqdwm6l/Yasu-animation-1.mp4?rlkey=8jp5e0b6j3mrs6btzw4ybz9uq&dl=0"
            height="100%"
            width="100%"
            onStart={onVideoStart}
            playing
            playsinline
            muted
          />
        </div>

        {/* Animation text + controls overlayed on top of video */}
        <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center">
          <div className="h-2/5 max-sm:mb-8 flex items-end sm:pb-3">
            <MusicControls className="lg:mb-[-2rem]" />
          </div>
          <div className="h-1/5 w-full max-w-5xl mx-auto text-center flex items-center justify-center">
            <FadingTextAnimation
              textItems={fadingTextAnimationArray}
              onAnimationComplete={animationComplete}
            />
          </div>
          <div className="h-2/5 flex w-full justify-end items-end">
            <Button
              className="text-white capitalize mb-32 mr-4 sm:mb-20 sm:mr-20 lg:mb-32 lg:mr-32"
              onClick={animationComplete}
            >
              <Typography variant="h4" component="p">
                Skip
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YasuAnimation;
