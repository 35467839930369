import "./Footer.scss";
import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";

// Icon pulled for contact us here
import instagramIcon from "../../../assets/images/icons/share/instagram.png";
import linkedinIcon from "../../../assets/images/icons/share/LinkedIn.png";
import youtubeIcon from "../../../assets/images/icons/share/YouTube.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="w-full overflow-hidden bg-black h-auto flex justify-start">
      {/* Main wrapper */}
      <div className="my-10 sm:mx-2">
        {/* Grid layout used to organized elements */}
        <Grid container>
          {/* World of Yasu logo goes here */}
          <Grid className="flex justify-center" item xs={12} md={2}>
            {/* Back to home page */}
            <NavLink to="/" className="max-md:hidden mb-7 flex items-center">
              <img src={"logo192.png"} alt="Yasu logo" width={64} height={64} />
            </NavLink>
          </Grid>
          {/* The World of Yasu Inc section and Contact us here section tied together thats why they are in this grid together with double size (2 unit for each) */}
          <Grid item xs={12} md={3}>
            <Grid container>
              <Grid item xs={6} md={12}>
                <Container fixed className="sm:mx-2 my-2">
                  {/** The World of Yasu Inc. section */}
                  <Stack>
                    <Typography className="font-bold text-sm" gutterBottom>
                      The World of Yasu Inc.
                    </Typography>
                    <Typography
                      className="mt-4 text-xs"
                      variant="subtitle2"
                      gutterBottom
                    >
                      850-370 King Street West Toronto, ON, M5V 1J6
                    </Typography>
                  </Stack>
                </Container>
              </Grid>
              <Grid item xs={6} md={12}>
                <Container fixed className="sm:mx-2 my-2">
                  {/** Contact us here section */}
                  <Stack>
                    <Typography className="mb-2 text-xs" gutterBottom>
                      Contact us here
                    </Typography>
                    <Stack
                      className="mt-2"
                      direction="row"
                      flexWrap="wrap"
                      useFlexGap
                      spacing={2}
                    >
                      <Box className="flex justify-center items-center">
                        {/** Instagram address */}
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.instagram.com/theworldofyasu"
                        >
                          <img
                            src={instagramIcon}
                            alt="instagram"
                            height="34px"
                            flex-shrink="0"
                          />
                        </Link>
                      </Box>
                      <Box className="flex justify-center items-center">
                        {/** LinkedIn address */}
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.linkedin.com/company/the-world-of-yasu/"
                        >
                          <img
                            src={linkedinIcon}
                            alt="linkedin"
                            height="22px"
                            flex-shrink="0"
                          />
                        </Link>
                      </Box>
                      <Box className="flex justify-center items-center">
                        {/** Youtube address */}
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.youtube.com/channel/UCMxUMOpCoLAeQLqcaCJjLQA"
                        >
                          <img
                            src={youtubeIcon}
                            alt="youtube"
                            height="25px"
                            flex-shrink="0"
                          />
                        </Link>
                      </Box>
                    </Stack>
                  </Stack>
                </Container>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={2}>
            <Container fixed className="sm:mx-2 my-2">
              {/** Company section */}
              <Stack>
                <Typography className="font-bold text-sm" gutterBottom>
                  Company
                </Typography>
                <NavLink to="/creators" className="text-white no-underline">
                  <Typography
                    className="mt-4 text-xs"
                    variant="subtitle2"
                    gutterBottom
                  >
                    Our Artists
                  </Typography>
                </NavLink>
              </Stack>
            </Container>
          </Grid>
          <Grid item xs={6} md={3}>
            <Container fixed className="sm:mx-2 my-2">
              {/** Navigate section */}
              <Stack>
                <Typography className="font-bold text-sm" gutterBottom>
                  Navigate
                </Typography>
                <NavLink to="/" className="text-white no-underline">
                  <Typography className="mt-4 text-xs" variant="subtitle2">
                    Explore Drink
                  </Typography>
                </NavLink>
                <NavLink
                  to="/explore-world"
                  className="text-white no-underline"
                >
                  <Typography className="mt-4 text-xs" variant="subtitle2">
                    Explore World
                  </Typography>
                </NavLink>
                <NavLink
                  to="/world-building"
                  className="text-white no-underline"
                >
                  <Typography className="mt-4 text-xs" variant="subtitle2">
                    World Building Resources
                  </Typography>
                </NavLink>
              </Stack>
            </Container>
          </Grid>
          <Grid item xs={6} md={2}>
            <Container fixed className="sm:mx-2 my-2">
              {/** Legal section */}
              <Stack>
                <Typography className="font-bold text-sm" gutterBottom>
                  Legal
                </Typography>
                {/** Link to Terms And Conditions page */}
                <NavLink to="/terms" className="text-white no-underline">
                  <Typography className="mt-4 text-xs" variant="subtitle2">
                    Terms And Conditions
                  </Typography>
                </NavLink>
                {/** Link to Privacy page */}
                <NavLink to="/privacy" className="text-white no-underline">
                  <Typography className="mt-4 text-xs" variant="subtitle2">
                    Privacy
                  </Typography>
                </NavLink>
              </Stack>
            </Container>
          </Grid>
        </Grid>
        {/* End of the main grid layout */}
      </div>
      {/* End of the main wrapper */}
    </footer>
  );
};
export default Footer;
