import HeroContent from "../../shared/YasuLib/Hero/HeroContent/HeroContent";
import HeroTitle from "../../shared/YasuLib/Hero/HeroTitle/HeroTitle";
import HeroText from "../../shared/YasuLib/Hero/HeroText/HeroText";

import { Grid, Typography } from "@mui/material";
import "./Creators.scss";
import { creatorsArray, title } from "./Creators.data";
import Card from "./Card/Card";
import MainContent from "../../shared/YasuLib/MainContent/MainContent";
import Hero from "../../shared/YasuLib/Hero/Hero";

const Creators = () => {
  return (
    <div id="Creators">
      <Hero className="!h-fit sm:pt-48 bg-black">
        <HeroContent>
          <HeroTitle>The Yasu Creators</HeroTitle>
          <HeroText>
            Yasu was created by some of the most brilliant humans in the world.
            It is our pleasure to showcase them to the world and share their
            talented portfolios. This page includes contractors and freelancers,
            part-timers, full-timers and everything in between.
          </HeroText>
        </HeroContent>
      </Hero>

      <MainContent className="bg-midnightDark">
        {/** Artist section */}
        <div className="max-w-screen-2xl mx-auto pt-20 pb-20 sm:pb-40">
          <Typography
            variant="h2"
            className="text-xl sm:text-4xl my-0 sm:mb-5 font-bold sm:font-extrabold text-primary"
          >
            Artists
          </Typography>
          <Grid
            className="mt-1 mb-24"
            container
            spacing={{ xs: 2, md: 8, xl: 12 }}
          >
            {/** This is where section for artists are filtered from whole list t and c objects are both creator objects */}
            {creatorsArray
              .filter((t) => t?.title === title.Artists)
              .map((c, i) => (
                <Grid key={i} className="pt-6" item xs={12} md={6} xl={4}>
                  {/** Creator object send to card component here */}
                  <Card copy={c.copy} fullName={c.fullName} links={c.links} />
                </Grid>
              ))}
          </Grid>
          {/** Technology and Multimedia section */}
          <Typography
            variant="h2"
            className="text-xl sm:text-4xl my-0 sm:mb-5 font-bold sm:font-extrabold text-primary"
          >
            Technology and Multimedia
          </Typography>
          <Grid
            className="mt-1 mb-24"
            container
            spacing={{ xs: 2, md: 8, xl: 12 }}
          >
            {/** This is where section for Technology and Multimedia are filtered from whole list t and c objects are both creator objects */}
            {creatorsArray
              .filter((t) => t?.title === title.TechnologyAndMultimedia)
              .map((c, i) => (
                <Grid key={i} className="pt-6" item xs={12} md={6} xl={4}>
                  {/** Creator object send to card component here */}
                  <Card copy={c.copy} fullName={c.fullName} links={c.links} />
                </Grid>
              ))}
          </Grid>
          {/** Copy, Research, and UX section */}
          <Typography
            variant="h2"
            className="text-xl sm:text-4xl my-0 sm:mb-5 font-bold sm:font-extrabold text-primary"
          >
            Digital and Strategy
          </Typography>
          <Grid className="mt-1" container spacing={{ xs: 2, md: 8, xl: 12 }}>
            {/** This is where section for Copy, Research, and UX are filtered from whole list t and c objects are both creator objects */}
            {creatorsArray
              .filter((t) => t?.title === title.DigitalAndStrategy)
              .map((c, i) => (
                <Grid key={i} className="pt-6" item xs={12} md={6} xl={4}>
                  {/** Creator object send to card component here */}
                  <Card copy={c.copy} fullName={c.fullName} links={c.links} />
                </Grid>
              ))}
          </Grid>
        </div>
      </MainContent>
    </div>
  );
};

export default Creators;
