import { CloseOutlined } from '@mui/icons-material';
import { AppBar, Button, Dialog, IconButton, List, ListItem, Toolbar } from '@mui/material';
import { FC, useState } from 'react';
import { RightSlideTransition } from '../../../../utils/transitions';
import { NavLink } from 'react-router-dom';
import menu from '../../../../assets/images/icons/menu-icon.svg';
import { GenericProps } from '../../../../types/types';
import { navItems } from '../navItems';


const MobileNavbar: FC<GenericProps> = ({ className = '' }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={`MobileNavbar ${className}`}>
            <Button onClick={handleClickOpen}>
                <img src={menu} height={42} width={42} alt='hamburger menu' />
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={RightSlideTransition}
                PaperProps={{ className: 'bg-none bg-midnight px-6 py-6' }}
            >
                <AppBar color='transparent' elevation={0} className='relative mb-8'>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            color='inherit'
                            onClick={handleClose}
                            aria-label='close'
                        >
                            <CloseOutlined />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <List>
                    {navItems.common.map((item) => (
                        <ListItem key={item.label}>
                            <NavLink
                                to={item.path}
                                className={({ isActive }) => `text-white font-semibold text-2xl flex items-center py-1 ${isActive ? 'underline decoration-2 underline-offset-4' : 'no-underline'}`}
                                onClick={handleClose}
                            >
                                <img className='mr-3' src={item.icon} height={item.iconHeight || 24} width={item.iconWidth || 24} alt={`${item.label} icon`} />
                                <span>{item.label}</span>
                            </NavLink>
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </div>
    );
}

export default MobileNavbar;