import { FC, createContext, useState } from 'react';
import { GenericProps } from '../../types/types';

export interface IAmbientMusicContext {
    isPlaying: boolean;
    togglePlay: Function;
    showControls: boolean;
    setShowControls: Function;
}

export const AmbientMusicContext = createContext<IAmbientMusicContext | null>(null);

const AmbientMusicProvider: FC<GenericProps> = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showControls, setShowControls] = useState(true);

    // play/pause music
    const togglePlay = () => {
        setIsPlaying(prevState => !prevState);
    };

    // provider values to expose
    const theValues = {
        isPlaying,
        togglePlay,
        showControls,
        setShowControls,
    };

    return (
        <AmbientMusicContext.Provider value={theValues}>
            {children}
        </AmbientMusicContext.Provider>
    );
}

export default AmbientMusicProvider;