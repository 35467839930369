import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { Alert, Snackbar } from '@mui/material';
import useFirebase from '../../../../context/FirebaseProvider/useFirebase';
import { httpsCallable } from 'firebase/functions';
import { FC, useState } from 'react';
import { GenericProps } from '../../../../types/types';
import { emailValidator } from '../../../../utils/formValidators';
import PillButton from '../../YasuLib/PillButton/PillButton';
import Input from '../../YasuLib/Input/Input';

interface FormData {
    email: string;
}

const NewsletterForm: FC<GenericProps> = ({ className = '' }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const { register, handleSubmit, formState: { errors }, setError, reset } = useForm<FormData>();
    const { functions } = useFirebase();

    // closes the success snackbar
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    // form submission
    const onSubmit = async (data: FormData) => {
        // start loading
        setIsLoading(true);
        try {
            // call cloud function
            const signUpForNewsletter = httpsCallable<FormData>(functions, 'signUpForNewsletter');
            await signUpForNewsletter(data);

            // cleanup -> open success snackbar and reset form
            setOpen(true);
            reset();
        } catch (err) {
            console.error('Error subscribing to newsletter ', err);
            // default generic error message
            let errorMessage = 'Error subscribing';

            // if proper error get error message
            if (err instanceof Error) {
                errorMessage = err.message;
            }

            // set custom form error
            setError('email', {
                type: 'manual',
                message: errorMessage,
            });

        } finally {
            // stop loading
            setIsLoading(false);
        }

    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className={`${className}`} noValidate>
                <Box
                    className='flex flex-col sm:flex-row items-start justify-center mx-auto'
                    component='div'
                >
                    <div className='flex flex-col max-sm:w-full'>
                        <Input type='email'
                            {...register('email', emailValidator)}
                            className={`w-full sm:w-[300px] sm:mr-[-20px] sm:z-10`}
                            placeholder='Your email here'
                            autoComplete='off' // covers error message
                            errorMessage={errors.email?.message}
                        />
                    </div>

                    <PillButton className='sm:max-w-[150px] sm:rounded-l-none' type='submit' isLoading={isLoading} disabled={isLoading}>
                        Submit</PillButton>
                </Box>
            </form>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} className='text-white' variant='filled' severity='success'>
                    Thank you for subscribing to our newsletter
                </Alert>
            </Snackbar>
        </>
    );
}

export default NewsletterForm;
