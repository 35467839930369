import home from "../../../assets/images/icons/home-icon.svg";
import order from "../../../assets/images/icons/order-icon.svg";

// links for different screen sizes + common links shared on all screens
export const navItems = {
  common: [
    { label: "Explore Drink", path: "/", icon: home },
    {
      label: "Explore World",
      path: "/explore-world",
      icon: order,
      iconHeight: 28,
      iconWidth: 28,
    },
  ],
  desktop: [],
  mobile: [],
};
