import { FC, createContext, useState } from 'react';
import { GenericProps } from '../../types/types';

export interface IShowAnimationContext {
    showAnimation: boolean;
    setShowAnimation: Function;
}

export const ShowAnimationContext = createContext<IShowAnimationContext | null>(null);

// to track if animation has already been played on the site so it is
// only shown to the user once, and not every time to home page is loaded
const ShowAnimationProvider: FC<GenericProps> = ({ children }) => {
    const [showAnimation, setShowAnimation] = useState(true);


    // provider values to expose
    const theValues = {
        showAnimation,
        setShowAnimation,
    };

    return (
        <ShowAnimationContext.Provider value={theValues}>
            {children}
        </ShowAnimationContext.Provider>
    );
}

export default ShowAnimationProvider;