import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import "./SupplementTable.scss";
import {
  Supplement,
  CategoryType,
  SupplementType,
  supplementOptionsArray,
  categoryOptionsArray,
  supplementsArray,
} from "./supplements.data.types";
import FullLegend from "./Legend/FullLegend/FullLegend";
import Disclaimer from "./Disclaimer/Disclaimer";
import body from "../../../../assets/images/BiologicalParts/Body.png";

const SupplementTable = () => {

  const [supplements, setSupplements] =
    useState<Supplement[]>(supplementsArray);

  const [filter, setFilter] = useState<CategoryType | SupplementType | null>(
    null
  );

  const [categoryTypeSelected, setCategoryTypeSelected] =
    useState<boolean>(false);

  // dialog vars
  const [selectedSupplement, setSelectedSupplement] =
    useState<Supplement | null>(null);
  const [open, setOpen] = useState(false);

  // open dialog
  const openModal = (supplement: Supplement) => {
    setSelectedSupplement(supplement);
    setOpen(true);
  };

  // close dialog
  const handleClose = () => {
    setOpen(false);
  };
  
  // control grid filters
  const filterSupplements = (
    filterBy: CategoryType | SupplementType,
    type: "categoryType" | "supplementType"
  ) => {
    setCategoryTypeSelected(false);

    // if reclicking current filter, remove filter
    if (filterBy === filter) {
      setFilter(null);
      setSupplements(supplementsArray);
      return;
    }

    // temp vars for calculations. Initialize to current values
    let filteredSupplements = supplements;

    // handle filter options
    switch (type) {
      case "categoryType":
        filteredSupplements = supplementsArray.filter((sup) =>
          sup.category.includes(filterBy as CategoryType)
        );
        setCategoryTypeSelected(true);
        break;
      case "supplementType":
        filteredSupplements = supplementsArray.filter((sup) =>
          sup.type.includes(filterBy as SupplementType)
        );
        break;
      default:
        console.error("Invalid filter was provided ", type);
        return;
    }

    setFilter(filterBy);
    setSupplements(filteredSupplements);
  };

  return (
    <div className="SupplementTable">
      {/* category filters */}
      <div className="flex justify-center md:space-x-6 space-x-2 mb-6">
        {categoryOptionsArray.map((item, i) => (
          <Button
            variant="contained"
            key={i}
            onClick={() => filterSupplements(item.filter, "categoryType")}
            className={`w-full rounded-2xl p-2 md:text-lg text-sm md:font-bold capitalize border-solid border-2 border-purple hover:bg-purple max-sm:h-8 grow ${
              filter === item.filter ? "bg-purple" : "bg-transparent"
            }`}
          >
            {item.text}
          </Button>
        ))}
      </div>

      {/* category filters description */}
      {categoryOptionsArray.find((item) => filter === item.filter) && (
        <div className="bg-purple p-4 rounded-2xl mb-6">
          <p className="font-quattro m-0 md:text-lg sm:text-sm text-xs">
            {
              categoryOptionsArray.find((item) => filter === item.filter)
                ?.description
            }
          </p>
        </div>
      )}

      {/* mobile supplement type filters */}
      <div className="flex justify-center space-x-2 md:hidden mt-10">
        {supplementOptionsArray.map((item, i) => (
          <Button
            variant="contained"
            key={i}
            onClick={() => filterSupplements(item.filter, "supplementType")}
            className={`w-full rounded-2xl p-2 text-sm capitalize border-solid border-2 border-primary hover:bg-primary max-sm:h-8 grow ${
              filter === item.filter ? "bg-primary" : "bg-transparent"
            }`}
          >
            {item.text}
          </Button>
        ))}
      </div>

      {/* periodic table grid */}
      <Grid
        container
        spacing={{ xs: 2, sm: 3, lg: 3 }}
        columns={{ xs: 3, sm: 4, lg: 4 }}
        className="mt-1"
      >
        {supplements.map((item, i) => (
          // each individual grid item
          <Grid item key={i} xs={1}>
            <Button
              onClick={() => openModal(item)}
              className={`h-full w-full text-white bg-${item.type} rounded-3xl md:rounded-none capitalize flex flex-col items-start p-2 md:p-5 md:h-[9rem] max-md:aspect-square overflow-hidden`}
            >
              <span className="text-xs sm:text-base text-left md:text-lg font-bold leading-4">
                {item.name}
              </span>
              {item.subtitle && (
                <span className="text-left text-[0.625rem] sm:text-xs md:leading-3 font-normal leading-4">
                  {item.subtitle}
                </span>
              )}
            </Button>
          </Grid>
        ))}
      </Grid>

      <FullLegend className="hidden md:flex" />

      <Disclaimer categoryTypeSelected={categoryTypeSelected} />

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={`MuiDialog-Supplement SupplementDialog-${selectedSupplement?.type}`}
      >
        <DialogTitle
          className="flex justify-between items-center"
          id="scroll-dialog-title"
        >
          <span className="md:text-4xl text-3xl font-bold">{selectedSupplement?.name}</span>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            component="div"
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <h3 className="text-white">What is it?</h3>
            {selectedSupplement?.whatIsIt}

            <h3 className="text-white">What does it do?</h3>
            <div className="flex justify-center">
              <img src={body} alt="body" className="mb-4 sm:h-72 h-56"/>
            </div>
  
            {selectedSupplement?.biologicalDetails}
            
            <h3 className="text-white mb-0">Science brought to you by:</h3>

            {selectedSupplement?.scienceLinks.map((scienceLink, i) => (
              <div className="font-quattro text-white my-1 text-sm" key={i}>
                {scienceLink.text}<Link target='_blank' rel='noreferrer' href={scienceLink.link} className='text-white decoration-white'>{scienceLink.underlinedText}</Link>
              </div>
            ))}
            

          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SupplementTable;
