import "./Explore.scss";
import YasuAnimation from "./YasuAnimation/YasuAnimation";
import useAnimationComplete from "../../../../context/ShowAnimationProvider/useShowAnimation";
import { useState, AnimationEvent } from "react";
import Sinkhole from "../Sinkhole/Sinkhole";

const Explore = () => {
  const [isAnimationComplete, setAnimationComplete] = useState(false);
  const { showAnimation, setShowAnimation } = useAnimationComplete();

  /**
   * For triggering the fade-out of animation section and
   * fade-in of other site content
   */
  const handleYasuAnimationComplete = () => {
    setAnimationComplete(true);

    // scroll to top of page to make sure user and navbar
    // are positioned at the top after animation
    window.scrollTo(0, 0);
  };

  /**
   * For removing the animation section from the DOM after it has faded out.
   * When the fade-out on the HomeYasuAnimation is complete, update global state
   * so it isn't shown again on this page (unless the user refreshes, of course)
   */
  const handleAnimationEnd = (e: AnimationEvent<HTMLDivElement>) => {
    // check only for the animation and element we want and ignore the others
    if (
      (e.target as HTMLDivElement).id === "YasuAnimation" &&
      e.animationName === "fadeOut"
    ) {
      setShowAnimation(false);
    }
  };

  return (
    <div className="Explore h-full">
      {/* Header */}
      <div>
        {showAnimation && (
          <div
            id="YasuAnimation"
            className={`YasuAnimationSection fixed w-full h-screen ${
              isAnimationComplete ? "fade-out" : "z-20"
            }`}
            onAnimationEnd={handleAnimationEnd}
          >
            {/* z-20 sets z index higher than the AmbientMusic global FAB (z-10) so that the animation is displayed on top. Only apply z-20 until fade-out class is applied so the faded out animation does not prevent clicking on the FAB. While waiting for animation to load, bg-midnight sets background colour so it is not transparent and hides elements underneath (ie FAB ambient music) */}
            <YasuAnimation
              className={`h-screen`}
              onYasuAnimationComplete={handleYasuAnimationComplete}
            />
          </div>
        )}

        <div className={`Explore-bg ${isAnimationComplete ? "fade-in" : ""}`}>
          <Sinkhole />
        </div>

        {/* <Hero className={`Explore-bg ${isAnimationComplete ? "fade-in" : ""}`}>
          <HeroContent>
            <PillButton
              className="max-sm:w-28 sm:w-44 rounded-3xl sm:h-12"
              component={NavLink}
              to="/sinkhole"
            >
              Enter The Sinkhole
            </PillButton>
          </HeroContent>
        </Hero> */}
      </div>
    </div>
  );
};

export default Explore;
