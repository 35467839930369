import { FC } from 'react';
import { GenericProps } from '../../../../../types/types';
import { imageMap } from '../supplements.data.types';

/**
 * Contains styling for hero content including center alignment, margins,
 * and spacing between each item on the hero
 */
const PopupBioTitle: FC<GenericProps> = ({ children, className = '' }) => {
    return (
        <div className="grid grid-cols-[auto,1fr] items-center gap-3">
            {typeof children === 'string' && imageMap[children] && (
                <img src={imageMap[children]} alt={children} width="30" className="mt-auto mb-1" />
            )}
            <h3 className="text-white mb-0">{children}</h3>
        </div>
    );
}

export default PopupBioTitle;